import React from 'react';
import {
  Backdrop,
  Box,
  Button,
  FormControl,
  Paper,
  TextField,
  Typography,
  styled,
} from '@material-ui/core';
import { useRequest, useUX } from '../../../shared/hooks';

const ModalUser = ({ languages = [], callback = () => {} }) => {
  const api = useRequest();
  const {
    actions: { alert, setLoading },
  } = useUX();

  const [values, setValues] = React.useState({
    name: '',
    suffix: '',
    rtl: false,
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    const { name, suffix } = values;
    if (name.length > 0 && suffix.length > 0) {
      setLoading(true);
      api
        .post('/language', {
          name,
          suffix,
        })
        .then(({ data }) => {
          alert.openSuccess(`Language ${name} added successfully`);
          callback(data);
        })
        .catch(({ response }) => {
          alert.openError(response?.data?.message);
        })
        .finally(() => setLoading(false));
    } else {
      alert.openError('Fill all mandatory fields');
      return;
    }
  };

  return (
    <Backdrop
      open={true}
      style={{ zIndex: 990, backgroundColor: 'rgba(0, 0, 0, .75)' }}
    >
      <ModalSurface style={{ zIndex: 991 }}>
        <Typography variant="h4">Add new language</Typography>
        <Typography variant="caption">
          {`Existing languages: English, ${languages
            .map(({ name }) => name)
            .join(', ')}`}
        </Typography>
        <Form component="form" noValidate autoComplete="off">
          <FormControl style={{ width: '100%' }}>
            <InputText
              name="name"
              label="Display name"
              value={values.name}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>
          <FormControl style={{ width: '100%' }}>
            <InputText
              name="suffix"
              label="Language Intl."
              value={values.suffix}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>
        </Form>
        <Actions>
          <ModalButton
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Create
          </ModalButton>
          <ModalButton
            color="primary"
            variant="contained"
            onClick={() => callback()}
          >
            Close
          </ModalButton>
        </Actions>
      </ModalSurface>
    </Backdrop>
  );
};

export default ModalUser;

const ModalSurface = styled(Paper)({
  padding: '40px 25px',
  maxWidth: '420px',
});

const Form = styled(Box)({
  marginBottom: 20,
});

const InputText = styled(TextField)({
  marginTop: 20,
  display: 'block',
});

const Actions = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const ModalButton = styled(Button)({
  width: 'calc(50% - 5px)',
});
