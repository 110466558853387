import { Typography } from '@material-ui/core';

const Header = ({ name }) => (
  <>
    <Typography variant="h4">Add translation</Typography>
    <Typography variant="caption">
      Create a new translation to the module {name}
    </Typography>
  </>
);

export default Header;
