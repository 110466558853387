import React from 'react';
import { Box, TextField } from '@material-ui/core';
import Options from '../Options';
import { defineClassesProps } from '../../../../shared/helpers/editor';
import styles from '../../styles';

const TextFieldComponent = ({
  content = {},
  index = 0,
  review = false,
  handleOrderChange = () => {},
  handleDelete = () => {},
  readOnly = false,
  last = false,
}) => {
  const [showDelete, setShowDelete] = React.useState(false);
  const [firstRender, setFirstRender] = React.useState(true);
  const ref = React.useRef(null);

  const editable = !!handleDelete;

  React.useEffect(() => {
    if (firstRender && !!ref.current) {
      ref.current.childNodes[0].childNodes[0].value = content.value;
      setFirstRender(false);
    }
  }, [content.value, firstRender, index]);

  const classes = styles();
  const classesProps = defineClassesProps(classes, review);

  return (
    <Box
      className={classes.fieldContainer}
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
    >
      <Box className={classes.field}>
        <TextField
          id={content.id}
          ref={ref}
          fullWidth
          multiline
          variant="outlined"
          disabled={readOnly}
          InputProps={classesProps}
          inputProps={{
            style: {
              lineHeight: 1.1,
              fontSize: '1.5rem',
            },
            'data-content': true,
          }}
        />
      </Box>
      <Box className={classes.actionContainer}>
        {!readOnly && editable && showDelete && (
          <Options
            index={index}
            last={last}
            handleOrderChange={handleOrderChange}
            handleDelete={handleDelete}
          />
        )}
      </Box>
    </Box>
  );
};

export default TextFieldComponent;
