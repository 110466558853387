import { makeStyles } from '@material-ui/core';
import styles from '../../assets/styles';

export default makeStyles((theme) => ({
  ...styles,
  actionsList: {
    marginTop: 20,
    '& > * + *': {
      marginLeft: 10,
    },
  },
}));
