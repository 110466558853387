import { Typography } from '@material-ui/core';

const Header = ({ subtitle, title }) => (
  <>
    {title && <Typography variant="h4">{title}</Typography>}
    {subtitle && <Typography variant="caption">{subtitle}</Typography>}
  </>
);

export default Header;
