import React from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { mapIdsToEntity } from '../../../shared/helpers/api';
import ModalUser from './ModalUser';
import styles from '../../../assets/styles';

const TableUsers = ({ usersPayload = [], roles = [], languages = [] }) => {
  const [modalPayload, setModalPayload] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const classes = styles();

  const handleEdit = (user = {}) => {
    setModalPayload({
      id: user._id,
      idKeycloak: user.idKeycloak,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      languages: user.languages.map(({ _id }) => _id),
      roles: user.roles.map(({ _id }) => _id),
      additionalInformation: user.additionalInformation,
    });
  };

  React.useEffect(() => {
    if (usersPayload.length > users.length) {
      setUsers(usersPayload);
    }
  }, [users.length, usersPayload]);

  const handleModalAction = (data = null) => {
    if (data) {
      const updatedUser = {
        ...data,
        languages: mapIdsToEntity(data.languages, languages),
        roles: mapIdsToEntity(data.roles, roles),
      };
      const userUpdatedIndex = users.findIndex(
        (user) => user._id === updatedUser._id
      );
      setUsers([
        ...users.slice(0, userUpdatedIndex),
        updatedUser,
        ...users.slice(userUpdatedIndex + 1),
      ]);
    }
    setModalPayload(null);
  };

  const headerStyle = { color: '#000', fontSize: '1rem' };

  return (
    <>
      <TableContainer component={Box}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={headerStyle} className={classes.contrastText}>
                Name
              </TableCell>
              <TableCell style={headerStyle} className={classes.contrastText}>
                E-mail
              </TableCell>
              <TableCell style={headerStyle} className={classes.contrastText}>
                Languages
              </TableCell>
              <TableCell style={headerStyle} className={classes.contrastText}>
                Roles
              </TableCell>
              <TableCell style={headerStyle} className={classes.contrastText}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user._id}>
                <TableCell
                  className={classes.contrastText}
                >{`${user.firstName} ${user.lastName}`}</TableCell>
                <TableCell className={classes.contrastText}>
                  {user.email}
                </TableCell>
                <TableCell className={classes.contrastText}>
                  {user.languages.map(({ name }) => name).join(', ')}
                </TableCell>
                <TableCell className={classes.contrastText}>
                  {user.admin
                    ? 'Administrator'
                    : user.roles.map(({ name }) => name).join(', ')}
                </TableCell>
                <TableCell>
                  <StyledIconButton onClick={() => handleEdit(user)}>
                    <Edit />
                  </StyledIconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!!modalPayload && (
        <ModalUser
          initialState={modalPayload}
          roles={roles}
          languages={languages}
          callback={handleModalAction}
        />
      )}
    </>
  );
};

export default TableUsers;

const StyledIconButton = styled(IconButton)({
  padding: 5,
});
