import { createContext, useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';
import { useUX } from '../hooks';
import ERRORS from '../constants/errors';
import { USER_STATE } from '../constants/user';

const { REACT_APP_API_BASE_URL: BASE_URL } = process.env;

const getUserState = (keycloak, initialized) => {
  if (!initialized) return USER_STATE.LOADING;
  return keycloak.authenticated ? USER_STATE.LOGGED_IN : USER_STATE.LOGGED_OUT;
};

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();
  const userState = getUserState(keycloak, initialized);
  const isLoading = userState === USER_STATE.LOADING;
  const isLoggedIn = userState === USER_STATE.LOGGED_IN;

  const {
    actions: { alert },
  } = useUX();

  const [keycloakUser, setKeycloakUser] = useState(null);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [hasRoles, setHasRoles] = useState(null);

  const fetchUser = useCallback(
    async (accessToken) =>
      axios
        .get(`${BASE_URL}/user`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(({ data }) => {
          if (process.env.NODE_ENV === 'development') console.log(data);
          return data;
        })
        .catch(() => {
          alert.openError(ERRORS.USER_ERROR);
          return '';
        }),
    [alert]
  );

  const login = useCallback(() => {
    keycloak.login();
  }, [keycloak]);

  useEffect(() => {
    setToken(keycloak.token);
  }, [keycloak.token]);

  useEffect(() => {
    if (token && !keycloakUser)
      keycloak.loadUserProfile().then((profile) => setKeycloakUser(profile));
  }, [keycloak, keycloakUser, token]);

  useEffect(() => {
    if (keycloakUser && !user)
      fetchUser(token).then((apiUser) => setUser(apiUser));
  }, [fetchUser, keycloakUser, token, user]);

  useEffect(() => {
    if (user && !hasRoles) {
      const rolesNames = user.roles?.map(({ name }) => name) ?? [];
      setHasRoles({
        isCreator: rolesNames.includes('creator'),
        isTranslator: rolesNames.includes('translator'),
        isViewer: rolesNames.includes('viewer'),
      });
    }
  }, [hasRoles, user]);

  const logout = useCallback(async () => {
    setUser(null);
    setToken(null);
    keycloak.logout();
  }, [keycloak]);

  return (
    <UserContext.Provider
      value={{
        data: {
          user,
          token,
        },
        actions: {
          login,
          logout,
          fetchUser,
        },
        state: {
          isLoading,
          isLoggedIn,
          isAdmin: user?.admin,
          isCreator: user?.admin || hasRoles?.isCreator,
          isTranslator: user?.admin || hasRoles?.isTranslator,
          isViewer: user?.admin || hasRoles?.isViewer,
          isOnlyViewer:
            hasRoles?.isViewer &&
            !user?.admin &&
            !hasRoles?.isCreator &&
            !hasRoles?.isTranslator,
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
