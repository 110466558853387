import React from 'react';
import {
  IconButton as IconButtonMaterial,
  Tooltip,
  useTheme,
} from '@material-ui/core';

const IconButton = ({
  children,
  color = 'primary',
  variant = '',
  label = '',
  ...props
}) => {
  const theme = useTheme();
  const iconColor =
    variant !== ''
      ? {
          color: theme.palette[variant].main,
        }
      : {};

  if (label === '') {
    return (
      <IconButtonMaterial style={iconColor} color={color} {...props}>
        {children}
      </IconButtonMaterial>
    );
  }
  return (
    <Tooltip title={label} arrow>
      <IconButtonMaterial style={iconColor} color={color} {...props}>
        {children}
      </IconButtonMaterial>
    </Tooltip>
  );
};

export default IconButton;
