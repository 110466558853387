import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRequest, useUX } from '../../shared/hooks';
import { replaceWhenCondition } from './helpers';

const useContents = ({ id = '', parentName = '' }) => {
  const [contentTypes, setContentTypes] = useState([]);
  const [entity, setEntity] = useState();
  const [mainEntity, setMainEntity] = useState();
  const [loadingMainContents, setLoadingMainContents] = useState(false);
  const [loadingNewContents, setLoadingNewContents] = useState(false);

  const api = useRequest();
  const {
    actions: { alert, setLoading },
  } = useUX();

  const getMainContents = useCallback(
    async (wrapperId) => {
      setLoadingMainContents(true);
      try {
        const { data } = await api.get(
          `/wrapper-${parentName}/${wrapperId}/content`
        );
        setMainEntity(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingMainContents(false);
      }
    },
    [api, parentName]
  );

  useEffect(() => {
    setLoading(
      contentTypes.length === 0 ||
        !entity ||
        loadingMainContents ||
        loadingNewContents
    );
  }, [
    contentTypes.length,
    entity,
    loadingMainContents,
    loadingNewContents,
    setLoading,
  ]);

  useEffect(() => {
    api
      .get('/wrapper-content-type')
      .then(({ data }) => {
        setContentTypes(data);
      })
      .catch((e) => {
        console.error(e);
        alert.openError(e?.response?.data?.message);
      });
  }, [alert, api]);

  useEffect(() => {
    api
      .get(`/${parentName}/${id}/content`)
      .then(({ data }) => {
        setEntity(data);
        const {
          language: { main },
          wrapper,
        } = data;
        if (!main) getMainContents(wrapper);
      })
      .catch((e) => {
        console.error(e);
        alert.openError(e?.response?.data?.message);
      });
  }, [alert, api, getMainContents, id, parentName]);

  const handleContentsUpdate = useCallback(
    async (contents) => {
      setLoadingNewContents(true);
      api
        .put(`/${parentName}/${id}/content`, contents)
        .then(({ data: newContents }) => {
          setEntity((oldEntity) => ({
            ...oldEntity,
            contents: replaceWhenCondition(
              contents,
              newContents,
              (el) => !el._id
            ),
          }));
        })
        .catch((e) => {
          console.error(e);
          alert.openError(e?.response?.data?.message);
        })
        .finally(() => {
          setLoadingNewContents(false);
        });
    },
    [alert, api, id, parentName]
  );

  const value = useMemo(
    () => ({
      fullContentTypes: contentTypes,
      contentTypes: contentTypes?.reduce(
        (map, { _id, name }) => ({
          ...map,
          [_id]: name,
        }),
        {}
      ),
      entity,
      mainEntity,
      handleContentsUpdate,
    }),
    [contentTypes, entity, handleContentsUpdate, mainEntity]
  );

  return value;
};

export default useContents;
