import { useCallback, useMemo, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { IconButton } from '../../components';

const STATES = {
  CLOSED: 0,
  LOADING: 1,
  OPENED: 2,
};

const Indicator = ({ onClick, state }) => {
  if (state === STATES.CLOSED)
    return (
      <IconButton label="Expand" onClick={onClick}>
        <ExpandMore />
      </IconButton>
    );
  if (state === STATES.OPENED)
    return (
      <IconButton label="Collapse" onClick={onClick}>
        <ExpandLess />
      </IconButton>
    );
  if (state === STATES.LOADING) return <CircularProgress />;
  return <></>;
};

const useLazyLoadedList = ({ service }) => {
  const [cache, setCache] = useState();
  const [state, setState] = useState(STATES.CLOSED);

  const trigger = useCallback(
    async () =>
      service().then((result) => {
        setCache(result);
        return result;
      }),
    [service]
  );

  const handleClick = useCallback(async () => {
    if (!cache) {
      setState(STATES.LOADING);
      await trigger()
        .then(() => setState(STATES.OPENED))
        .catch(() => setState(STATES.CLOSED));
    } else {
      setState((currentState) =>
        currentState === STATES.CLOSED ? STATES.OPENED : STATES.CLOSED
      );
    }
  }, [cache, trigger]);

  return useMemo(
    () => ({
      isClosed: state === STATES.CLOSED,
      isLoading: state === STATES.LOADING,
      isOpen: state === STATES.OPENED,
      Icon: () => <Indicator state={state} onClick={handleClick} />,
      trigger,
      value: cache,
    }),
    [cache, handleClick, state, trigger]
  );
};

export default useLazyLoadedList;
