import React from 'react';
import { Paper } from '@material-ui/core';
import styles from '../../assets/styles';

const Surface = ({ children, variant = null, ...props }) => {
  const classes = styles();
  const { className: additionalClasses, ...otherProps } = props;
  const variantClass = variant ? classes[variant] : '';
  return (
    <Paper
      className={`${additionalClasses} ${variantClass}`}
      elevation={3}
      {...otherProps}
    >
      {children}
    </Paper>
  );
};

export default Surface;
