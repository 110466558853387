import {
  Box,
  FormControl as MaterialFormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@material-ui/core';

const Form = ({
  defaultName,
  defaultDescription,
  languages,
  setValues,
  values,
}) => {
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <FormBody component="form" noValidate autoComplete="off">
      <FormControl>
        <InputLabel>Available Languages</InputLabel>
        <Select name="language" value={values.language} onChange={handleChange}>
          {languages.map(({ _id, available, name }) => (
            <MenuItem key={_id} value={_id} disabled={!available}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <InputText
        name="name"
        label="Name"
        value={values.name}
        onChange={handleChange}
        placeholder={defaultName}
        fullWidth
      />
      <InputText
        name="description"
        label="Description"
        value={values.description}
        onChange={handleChange}
        placeholder={defaultDescription}
        fullWidth
      />
      <Caption variant="body2">
        This will create a deep copy of the module in english to the new
        language
      </Caption>
    </FormBody>
  );
};

export default Form;

const Caption = styled(Typography)({
  marginTop: 25,
  fontSize: 10,
});

const FormBody = styled(Box)({
  paddingTop: 10,
  marginBottom: 20,
});

const FormControl = styled(MaterialFormControl)({
  width: '100%',
});

const InputText = styled(TextField)({
  marginTop: 20,
  display: 'block',
});
