import { makeStyles } from '@material-ui/core';
import styles from '../../assets/styles';

export default makeStyles((theme) => ({
  ...styles,
  paddedCard: {
    position: 'relative',
    marginBottom: 15,
    padding: 25,
    width: 'auto',
  },
}));
