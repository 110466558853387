const getModuleById = (api) => (id) =>
  api.get(`/module/${id}`).then(({ data }) => data);

const getModulesByWrapperId = (api) => (wrapperId) =>
  api.get(`/wrapper-module/${wrapperId}`).then(({ data }) => data.modules);

const getLanguages = (api) => () =>
  api.get('/language').then(({ data }) => data);

const getReviewTree = (api) => (id) =>
  api.get(`/module/${id}/review-tree`).then(({ data }) => data);

const services = (api) => ({
  getModuleById: getModuleById(api),
  getModulesByWrapperId: getModulesByWrapperId(api),
  getLanguages: getLanguages(api),
  getReviewTree: getReviewTree(api),
});

export default services;
