import { createContext, useCallback, useMemo, useReducer } from 'react';

const ACTIONS = {
  SET_OPEN_EDIT_INFORMATION: 'SET_OPEN_EDIT_INFORMATION',
  SET_OPEN_CREATE_ELEMENT: 'SET_OPEN_CREATE_ELEMENT',
  SET_OPEN_SORT: 'SET_OPEN_SORT',
  SET_OPEN_DELETE_ELEMENT: 'SET_OPEN_DELETE_ELEMENT',
  RESET: 'RESET',
};

const modalActionMap = {
  editInformation: ACTIONS.SET_OPEN_EDIT_INFORMATION,
  sort: ACTIONS.SET_OPEN_SORT,
  createElement: ACTIONS.SET_OPEN_CREATE_ELEMENT,
  deleteElement: ACTIONS.SET_OPEN_DELETE_ELEMENT,
};

const initialState = {
  isEditInformationOpened: false,
  isCreateElementOpened: false,
  isSortOpened: false,
  isDeleteElementOpened: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_OPEN_EDIT_INFORMATION: {
      return {
        ...state,
        isEditInformationOpened: action.payload,
      };
    }
    case ACTIONS.SET_OPEN_CREATE_ELEMENT: {
      return {
        ...state,
        isCreateElementOpened: action.payload,
      };
    }
    case ACTIONS.SET_OPEN_SORT: {
      return {
        ...state,
        isSortOpened: action.payload,
      };
    }
    case ACTIONS.SET_OPEN_DELETE_ELEMENT: {
      return {
        ...state,
        isDeleteElementOpened: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

const isValidModal = (modal) => Object.keys(modalActionMap).includes(modal);

export const ModalContext = createContext({});

const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const open = useCallback((modal = '') => {
    if (!isValidModal(modal)) throw new Error(`Unknown modal: ${modal}`);
    dispatch({ type: modalActionMap[modal], payload: true });
  }, []);

  const close = useCallback((modal = '') => {
    if (!isValidModal(modal)) throw new Error(`Unknown modal: ${modal}`);
    dispatch({ type: modalActionMap[modal], payload: false });
  }, []);

  const value = useMemo(
    () => ({
      state,
      actions: {
        open,
        close,
      },
    }),
    [close, open, state]
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export default ModalProvider;
