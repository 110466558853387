import React from 'react';
import { Chip, useTheme } from '@material-ui/core';

const WarningChip = ({ label = '', ...props }) => {
  const theme = useTheme();
  return (
    <Chip
      style={{
        backgroundColor: theme.palette.warning.light,
        marginLeft: 10,
        fontSize: 10,
      }}
      label={label}
      {...props}
    />
  );
};

export default WarningChip;
