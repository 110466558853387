import { Typography } from '@material-ui/core';

import { WarningChip } from '../../../../components';

import styles from '../../styles';

const Header = ({ description, name, review }) => {
  const classes = styles();

  return (
    <>
      <Typography className={classes.inlineText} variant="h4">
        {name}
        {review && <WarningChip label="Review Information" />}
      </Typography>
      <Typography variant="h6">{description}</Typography>
    </>
  );
};

export default Header;
