import React from 'react';
import TopicField from './TopicField';
import TextField from './TextField';
import ImageField from './ImageField';

const Field = ({
  content = {},
  index = 0,
  type = '',
  review = false,
  handleOrderChange = () => {},
  handleChange = () => {},
  handleDelete = () => {},
  readOnly = false,
  last = false,
}) => {
  switch (type.toLowerCase()) {
    case 'topic':
      return (
        <TopicField
          content={content}
          index={index}
          review={review}
          handleOrderChange={handleOrderChange}
          handleDelete={handleDelete}
          readOnly={readOnly}
          last={last}
        />
      );
    case 'text':
      return (
        <TextField
          content={content}
          index={index}
          review={review}
          handleOrderChange={handleOrderChange}
          handleDelete={handleDelete}
          readOnly={readOnly}
          last={last}
        />
      );
    case 'image':
      return (
        <ImageField
          content={content}
          index={index}
          review={review}
          handleOrderChange={handleOrderChange}
          handleChange={handleChange}
          handleDelete={handleDelete}
          readOnly={readOnly}
          last={last}
        />
      );
    default:
      return <></>;
  }
};

export default Field;
