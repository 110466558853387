import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useUser } from '../shared/hooks';

const PrivateRoute = ({ component: Component, ...other }) => {
  const location = useLocation();
  const {
    state: { isLoggedIn },
  } = useUser();
  return (
    <Route
      {...other}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/callback',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
