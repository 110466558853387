import { makeStyles } from '@material-ui/core';
import styles from '../../assets/styles';

export default makeStyles({
  ...styles,
  actionContainer: {
    width: '110px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: 0,
    },
  },
  fieldContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
  },
  field: {
    width: 'calc(100% - 110px - 25px)',
  },
  reviewContent: {
    borderColor: 'yellow', // TODO: choose a better color
  },
  backgroundContent: {
    position: 'absolute',
    top: 50,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, .75)',
  },
});
