import React from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../shared/hooks';
import styles from '../../assets/styles';

const Home = () => {
  const classes = styles();
  const {
    state: { isLoggedIn },
  } = useUser();
  const history = useHistory();

  React.useEffect(() => {
    if (isLoggedIn) history.push('/home');
  }, [history, isLoggedIn]);

  return (
    <>
      <Typography className={classes.contrastText} variant="h5">
        Click on Login to enter the platform
      </Typography>
      <Typography className={classes.contrastText} variant="caption">
        If you do not have a credential, send an e-mail to XXX@delorenzo.com.br
      </Typography>
    </>
  );
};

export default Home;
