import { useCallback, useEffect, useMemo } from 'react';
import {
  useLazyLoadedList,
  useRequest,
  useUX,
} from '../../../../../shared/hooks';

const useActivityItem = ({ id, setActivity }) => {
  const api = useRequest();
  const {
    actions: { alert },
  } = useUX();
  const service = useCallback(
    () =>
      api
        .get(`/activity/${id}`)
        .then(({ data }) => data)
        .catch(({ response }) =>
          alert.openError(response?.data?.message || 'Error fetching activity')
        ),
    [alert, api, id]
  );

  const { isOpen, Icon, trigger, value } = useLazyLoadedList({
    service,
  });

  useEffect(() => {
    if (value) setActivity(value);
  }, [setActivity, value]);

  return useMemo(
    () => ({
      isOpen,
      Icon,
      trigger,
    }),
    [Icon, isOpen, trigger]
  );
};

export default useActivityItem;
