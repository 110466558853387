import { useMemo } from 'react';
import { useUser } from './';

const usePermissions = (languageId = '') => {
  const {
    state: { isCreator, isTranslator, isViewer, isAdmin, isOnlyViewer },
    data: { user },
  } = useUser();

  const userHasLanguage = user?.languages?.includes(languageId);

  const permissionsObject = useMemo(
    () => ({
      canSort: isCreator,
      canEditInformation: isCreator || (isTranslator && userHasLanguage),
      canView: isCreator || isViewer || (isTranslator && userHasLanguage),
      canAddNew: isCreator,
      canDelete: isAdmin,
      canOnlyView: isOnlyViewer,
      canExport: isAdmin,
    }),
    [isAdmin, isCreator, isOnlyViewer, isTranslator, isViewer, userHasLanguage]
  );

  return permissionsObject;
};

export default usePermissions;
