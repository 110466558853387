import { useCallback, useEffect, useRef, useState } from 'react';

const useTimeout = ({
  callback = () => null,
  timeout = 1,
  startNow = false,
}) => {
  const timerRef = useRef(null);
  const [start, setStart] = useState(startNow);

  const clear = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  const startTimer = useCallback(() => {
    setStart(true);
  }, []);

  useEffect(() => {
    if (start) {
      timerRef.current = setTimeout(callback, timeout);
    }
    return clear;
  }, [callback, clear, start, timeout]);

  return [startTimer, clear];
};

export default useTimeout;
