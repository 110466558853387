import { useState } from 'react';
import { Box, Button, styled } from '@material-ui/core';

import { Surface } from '../../components';
import Accordion from './components/Accordion';
import useValidation, { ValidationsProvider } from './provider';
import ModalConfirmation from './components/ModalConfirmation';
import ModalCreate from './components/ModalCreate';
import { useUX } from '../../shared/hooks';

const Validation = () => {
  const { appendValidation, deleteValidationById, validations } =
    useValidation();
  const {
    actions: { alert },
  } = useUX();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [id, setId] = useState();

  const handleOpenDeleteModal = (id) => {
    setId(id);
    setOpenDeleteModal(true);
  };

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
  };

  const handleDeleteModalAction = (id) => {
    setOpenDeleteModal(false);
    if (!id) return;
    alert.openSuccess('Validation deleted successfully');
    deleteValidationById(id);
  };

  const handleCreateModalAction = (data) => {
    setOpenCreateModal(false);
    if (!data) return;
    appendValidation(data);
  };

  const selectedValidation = validations.find(({ _id }) => _id === id);

  return (
    <>
      <Surface>
        {validations.map(({ _id, title, description }) => (
          <Accordion
            key={_id}
            title={title}
            description={description}
            deleteHandler={() => handleOpenDeleteModal(_id)}
          />
        ))}
      </Surface>
      <SectionCreate>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenCreateModal}
        >
          Create
        </Button>
      </SectionCreate>
      <ModalConfirmation
        open={openDeleteModal}
        validation={selectedValidation}
        callback={handleDeleteModalAction}
      />
      <ModalCreate open={openCreateModal} callback={handleCreateModalAction} />
    </>
  );
};

const ValidationWrapped = () => (
  <ValidationsProvider>
    <Validation />
  </ValidationsProvider>
);

export default ValidationWrapped;

const SectionCreate = styled(Box)({
  marginTop: 20,
});
