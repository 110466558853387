import { convertBase64ToImageFile } from '../../shared/helpers/images';

export function replaceWhenCondition(
  oldArray = [],
  newArray = [],
  conditionFn = () => false
) {
  let index = 0;
  return oldArray.reduce(
    (array, curr) => [...array, conditionFn(curr) ? newArray[index++] : curr],
    []
  );
}

export async function contentsAdapter(api, node, contents) {
  const newImages = getNewImagesElements(node);
  const nodes = getContentsNodes(node);
  const urls = await handleNewImages(api, newImages);
  const adaptedContents = adaptContents(nodes, contents, urls);
  return adaptedContents;
}

async function handleNewImages(api, newImages) {
  if (!newImages.length) return Promise.resolve([]);
  const files = await Promise.all(
    newImages.map(({ node: image }) => convertBase64ToImageFile(image.src))
  );
  const formData = new FormData();
  for (const file of files) {
    formData.append('images', file);
  }
  const { data: bucketUrls } = await api.post('/media/images', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return newImages.reduce(
    (urlsWithIndex, { index }, currIndex) => ({
      ...urlsWithIndex,
      [index]: bucketUrls[currIndex],
    }),
    {}
  );
}

function getContentsNodes(node) {
  return Array.from(node.querySelectorAll('[data-content=true]'));
}

export function getNewImagesElements(node) {
  const nodes = getContentsNodes(node);
  return nodes
    .map((node, index) => ({
      node,
      index,
    }))
    .filter(
      ({ node: el }) =>
        el.tagName === 'IMG' && (!el.id || el?.src?.startsWith('data:image'))
    );
}

export function updateImagesOnDom(node, newContents) {
  const newImagesNodes = getNewImagesElements(node);
  newImagesNodes.forEach(({ node: imgNode, index }) => {
    const { _id, content } = newContents[index];
    imgNode.src = content;
    imgNode.id = _id;
  });
}

function adaptContents(nodes, contents, indexUrlMap) {
  return nodes.map((node, index) => {
    const obj = contents[index];
    const hasId = !!contents[index]._id;
    const nodeContent = getValueOfNode(node);
    const isInIndexUrlMap = !!indexUrlMap[index];
    const content = isInIndexUrlMap ? indexUrlMap[index].link : nodeContent;
    if (hasId)
      return {
        ...obj,
        content,
      };
    const { wrapper, ...rest } = obj;
    const type = wrapper.type._id;
    return {
      ...rest,
      type,
      content,
    };
  });
}

// function adaptContents(nodes, contents, newImagesUrls) {
//   const indexes = newImagesUrls.map(({ index }) => index);
//   const body = nodes.map((node, index) => {
//     const hasId = !!contents[index]._id;
//     const isImage = isNodeImage(node);
//     const content = getValueOfNode(node);
//     if (hasId) return { ...contents[index], content };
//     const { wrapper, ...info } = contents[index];
//     const type = wrapper.type._id;
//     return {
//       ...info,
//       type,
//       content: isImage ? newImagesUrls[newImageIndex++].link : content,
//     };
//   });
//   console.log(newImageIndex);
//   return body;
// }

function isNodeImage(node) {
  return node.tagName === 'IMG';
}

function getValueOfNode(node) {
  return isNodeImage(node) ? node.src : node.value;
}
