import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@material-ui/core';

import { Surface } from '../../components';
import ActionsList from './components/ActionsList';
import Header from './components/Header';
import LanguageList from './components/LanguageList';
import Modals from './components/Modals';
import Timestamps from './components/Timestamps';
import useModule, { ModuleProvider } from './provider';
import FeatureModalsProvider from '../../shared/providers/feature-modals';

import styles from './styles';
import TopicsList from './components/Children/TopicsList';

const Module = () => {
  const classes = styles();
  const { id } = useParams();
  const {
    data: { moduleInformation, siblingsInformation },
    actions: { setModuleId },
  } = useModule();

  useEffect(() => {
    setModuleId(id);
  }, [id, setModuleId]);

  if (!moduleInformation) return <></>;

  return (
    <Container>
      <Surface className={classes.paddedCard}>
        <Header {...moduleInformation} />
        <LanguageList
          currentLanguage={moduleInformation.language}
          siblings={siblingsInformation}
        />
        <Timestamps {...moduleInformation} />
        <ActionsList />
        <Modals />
      </Surface>
      <Surface className={classes.paddedCard} elevation={3}>
        <TopicsList />
      </Surface>
    </Container>
  );
};

const ModuleWrapped = () => (
  <ModuleProvider>
    <FeatureModalsProvider name="module">
      <Module />
    </FeatureModalsProvider>
  </ModuleProvider>
);

export default ModuleWrapped;
