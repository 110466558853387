import { Box, Button, styled } from '@material-ui/core';
import streamSaver from 'streamsaver';

import {
  useFeatureModals,
  usePermissions,
  useUX,
  useUser,
} from '../../../../shared/hooks';
import useModule from '../../provider';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const ActionsList = () => {
  const {
    data: { isMain, moduleInformation },
    actions: { setOpenModalCreateSibling },
  } = useModule();
  const {
    actions: { open },
  } = useFeatureModals();
  const languageId = moduleInformation?.language._id;
  const permissions = usePermissions(languageId);
  const {
    data: { loading },
    actions: { alert, setLoading },
  } = useUX();
  const {
    data: { token },
  } = useUser();

  const handleExport = async () => {
    setLoading(true);
    console.log(`Bearer ${token}`);
    try {
      await fetch(`${baseUrl}/module/${moduleInformation._id}/json`, {
        method: 'GET',
        headers: {
          Accept: '*/*',
          Connection: 'keep-alive',
          Authorization: `Bearer ${token}`,
        },
      })
        .then(({ body: readableStream }) => {
          const fileStream = streamSaver.createWriteStream(
            `${moduleInformation.name || 'file'}.json`
          );

          if (readableStream.pipeTo) {
            return readableStream.pipeTo(fileStream);
          }
        })
        .catch(() => alert.openError('Error while exporting. Try again later.'))
        .finally(() => setLoading(false));
    } catch (error) {
      alert.openError(error?.response?.data?.message);
      setLoading(false);
    }
  };

  if (!moduleInformation) return <></>;

  return (
    <List>
      {permissions?.canEditInformation && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => open('editInformation')}
        >
          Edit Information
        </Button>
      )}
      {isMain && (
        <ActionButton
          variant="contained"
          color="primary"
          onClick={() => setOpenModalCreateSibling(true)}
        >
          Add new translation
        </ActionButton>
      )}
      {isMain && permissions?.canSort && (
        <ActionButton
          variant="contained"
          color="primary"
          onClick={() => open('sort')}
          disabled={moduleInformation.topics.length < 2}
        >
          Sort Topics
        </ActionButton>
      )}
      {permissions?.canExport && (
        <ActionButton
          variant="contained"
          onClick={handleExport}
          disabled={loading}
        >
          Export
        </ActionButton>
      )}
    </List>
  );
};

export default ActionsList;

const List = styled(Box)({
  display: 'flex',
  marginTop: 5,
});

const ActionButton = styled(Button)({
  marginLeft: 5,
});
