import React from 'react';
import { Button, Card, CardContent, CardActions } from '@material-ui/core';
import styles from '../../../assets/styles';

const CardModule = ({ children, openModuleFn, review }) => {
  const classes = styles();
  const variant = review ? classes.warning : '';
  return (
    <Card
      className={`${classes.flex} ${classes.flexColumn} ${classes.fullHeight} ${classes.justifySpaceBetween} ${variant}`}
    >
      <CardContent>{children}</CardContent>
      <CardActions>
        <Button color="primary" variant="contained" onClick={openModuleFn}>
          Open Module
        </Button>
      </CardActions>
    </Card>
  );
};

export default CardModule;
