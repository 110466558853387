import { Button, Typography } from '@material-ui/core';

import { InlineActions, Modal } from '../../../components';
import { useRequest, useUX } from '../../../shared/hooks';

const ModalConfirmation = ({ open, validation, callback }) => {
  const api = useRequest();
  const {
    data: { loading },
    actions: { alert, setLoading },
  } = useUX();
  const { _id, title } = validation ?? {};

  const handleSubmit = () => {
    setLoading(true);
    api
      .delete(`/validation/${_id}`)
      .then(() => callback(_id))
      .catch((err) => alert.openError(err?.response?.data?.message))
      .finally(() => setLoading(false));
  };

  return (
    <Modal open={open}>
      <Typography>{`Are you sure you want to delete "${title}"?`}</Typography>
      <InlineActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          onClick={() => callback()}
          disabled={loading}
        >
          Cancel
        </Button>
      </InlineActions>
    </Modal>
  );
};

export default ModalConfirmation;
