import { Box, List, ListItem, ListItemText, styled } from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

import { IconButton } from '../../../components';

const Form = ({ fieldsOrder, setFieldsOrder }) => {
  const handleOrderChange = (index = 0, amount = -1) => {
    const actionElement = fieldsOrder[index];
    const swappedElement = fieldsOrder[index + amount];
    const shift = Math.max(amount, 0);
    setFieldsOrder([
      ...fieldsOrder.slice(0, index + shift - 1),
      amount > 0 ? swappedElement : actionElement,
      amount > 0 ? actionElement : swappedElement,
      ...fieldsOrder.slice(index + shift + 1),
    ]);
  };

  if (!fieldsOrder) return <></>;

  return (
    <Container>
      <List>
        {fieldsOrder.map(({ _id, name }, index) => (
          <ListItem key={_id}>
            <ListItemText>{name}</ListItemText>
            <IconButton
              onClick={() => handleOrderChange(index, -1)}
              disabled={index === 0}
            >
              <ArrowUpward />
            </IconButton>
            <IconButton
              onClick={() => handleOrderChange(index, 1)}
              disabled={index === fieldsOrder.length - 1}
            >
              <ArrowDownward />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default Form;

const Container = styled(Box)({
  padding: '20px 0',
});
