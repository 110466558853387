import React from 'react';
import { Box, Grid, Icon, Paper, Typography, styled } from '@material-ui/core';
import { Dehaze, Title, Image, Videocam } from '@material-ui/icons';

const LayoutOptions = ({ options = {}, position = 0, callback = () => {} }) => {
  const Icons = {
    Text: {
      icon: <Dehaze />,
      description: 'Insert a block of text (paragraph)',
      enabled: true,
    },
    Topic: {
      icon: <Title />,
      description: 'Insert a topic, a slightly larger block of text',
      enabled: true,
    },
    Image: {
      icon: <Image />,
      description: 'Insert an image to the body',
      enabled: true,
    },
    Video: {
      icon: <Videocam />,
      description: 'Insert a video to the body',
      enabled: false,
    },
  };

  const handleClick = (_id, position) => {
    const editorFields = document.getElementById('editor-fields');
    const index = 2 * (position + 1);
    callback(_id, position);
    editorFields.children[index].scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Typography variant="h5">Layout options</Typography>
        <Typography variant="caption">
          {position
            ? `This element will be placed between element #${position} and #${
                position + 1
              }`
            : 'Insert the first item'}
        </Typography>
      </Grid>
      {Object.entries(options).map(
        ([_id, name]) =>
          Icons[name] && (
            <Grid item xs={12} sm={6} key={_id}>
              <CustomPaper
                elevation={3}
                onClick={() => handleClick(_id, position)}
              >
                <FlexBox>
                  <StyledIcon>{Icons[name].icon}</StyledIcon>
                  {name}
                </FlexBox>
                <Typography variant="caption">
                  {Icons[name].description}
                </Typography>
              </CustomPaper>
            </Grid>
          )
      )}
    </Grid>
  );
};

export default LayoutOptions;

const CustomPaper = styled(Paper)({
  position: 'relative',
  padding: 25,
  height: '100%',
  cursor: 'pointer',
});

const FlexBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const StyledIcon = styled(Icon)({
  marginRight: 5,
});
