import { useEffect, useState } from 'react';
import { Box, TextField, styled, useTheme } from '@material-ui/core';
import { defineClassesProps } from '../../../../shared/helpers/editor';
import styles from '../../styles';

const HeaderFields = ({
  readOnly = false,
  initialContents = [],
  setContents = () => {},
}) => {
  const [title, setTitle] = useState(null);
  const [resume, setResume] = useState(null);
  const [classesProps, setClassesProps] = useState({
    title: {},
    resume: {},
  });

  const classes = styles();
  const theme = useTheme();

  useEffect(() => {
    if (!title && !resume && initialContents.length) {
      const [initialTitle, initialResume] = initialContents;
      setTitle(initialTitle);
      setResume(initialResume);
      setClassesProps({
        title: defineClassesProps(classes, initialTitle?.review),
        resume: defineClassesProps(classes, initialResume?.review),
      });
    }
  }, [classes, initialContents, resume, title]);

  useEffect(() => {
    setContents([title, resume]);
  }, [resume, setContents, title]);

  if (!initialContents.length) return <></>;

  return (
    <Box style={{ paddingTop: 30, paddingRight: 0, position: 'relative' }}>
      <Box className={classes.backgroundContent} />
      <Box className={classes.fieldContainer}>
        <TitleFieldBox>
          <TextField
            fullWidth
            color="primary"
            value={title?.content}
            variant="outlined"
            disabled={readOnly}
            onChange={(event) =>
              setTitle({
                ...title,
                content: event.target.value,
              })
            }
            InputProps={classesProps.title}
            style={{
              color: readOnly
                ? theme.palette.primary.main
                : theme.palette.secondary.main,
            }}
            inputProps={{
              style: {
                color: theme.palette.primary.main,
                lineHeight: 1.1,
                fontSize: '1.75rem',
              },
              'data-content': true,
            }}
          />
        </TitleFieldBox>
      </Box>
      <Box className={classes.fieldContainer}>
        <ResumeFieldBox>
          <TextField
            fullWidth
            value={resume?.content}
            variant="outlined"
            disabled={readOnly}
            onChange={(event) =>
              setResume({
                ...resume,
                content: event.target.value,
              })
            }
            InputProps={classesProps.resume}
            inputProps={{
              style: {
                lineHeight: 1.1,
                fontSize: '.75rem',
              },
              'data-content': true,
            }}
          />
        </ResumeFieldBox>
      </Box>
    </Box>
  );
};

export default HeaderFields;

const TitleFieldBox = styled(Box)({
  width: '100%',
  position: 'relative',
});

const ResumeFieldBox = styled(Box)({
  width: '100%',
  padding: 20,
  paddingLeft: 40,
  paddingRight: 64,
  position: 'relative',
  marginTop: -42,
});
