import { useEffect } from 'react';
import axios from 'axios';
import useUser from './useUser';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const useRequest = () => {
  const {
    data: { token },
  } = useUser();

  useEffect(() => {
    api.interceptors.request.use(
      (config) => {
        config.headers['Authorization'] = `Bearer ${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }, [token]);

  return api;
};

export default useRequest;
