import { Collapse, List } from '@material-ui/core';

const LazyLoadedList = ({ children, open = false, style }) => (
  <Collapse in={open} timeout="auto">
    <List component="div" disablePadding style={style}>
      {children}
    </List>
  </Collapse>
);

export default LazyLoadedList;
