import { useState } from 'react';

const useLocalStorage = (key, initialValue) => {
  const normalizedKey = `smartsims_${key}`;
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(normalizedKey);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });
  const setValue = (value) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(normalizedKey, JSON.stringify(value));
    } catch (error) {
      console.log(error);
    }
  };
  const clearValue = () => {
    window.localStorage.removeItem(normalizedKey);
  };

  return [storedValue, setValue, clearValue];
};

export default useLocalStorage;
