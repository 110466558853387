import React from 'react';
import { Box } from '@material-ui/core';
import Field from './Field';
import HeaderFields from './Field/HeaderFields';
import LayoutOptions from './LayoutOptions';

import styles from '../../../assets/styles';

const LeftSide = ({
  mainEntity,
  contentTypes,
  insertionPosition = -1,
  layoutInsertCallback = () => {},
}) => {
  const classes = styles();
  const [mainHeaderContents, mainContents] = React.useMemo(() => {
    if (!mainEntity || !mainEntity.contents) return [[], []];
    return [mainEntity.contents.slice(0, 2), mainEntity.contents.slice(2)];
  }, [mainEntity]);

  return (
    <>
      <HeaderFields initialContents={mainHeaderContents} readOnly />
      {mainContents.map(
        ({
          _id,
          content,
          wrapper: {
            type: { _id: type },
          },
        }) => (
          <Field
            key={_id}
            content={{ id: _id, value: content }}
            type={contentTypes[type]}
            readOnly
          />
        )
      )}
      {insertionPosition !== null && (
        <Box className={classes.padding25}>
          <LayoutOptions
            options={contentTypes}
            position={insertionPosition}
            callback={layoutInsertCallback}
          />
        </Box>
      )}
    </>
  );
};

export default LeftSide;
