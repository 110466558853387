import { useState } from 'react';
import { ListItem, ListItemText } from '@material-ui/core';

import Actions from './components/Actions';
import Modals from './components/Modals';
import FeatureModalsProvider from '../../../../../shared/providers/feature-modals';
import { usePermissions } from '../../../../../shared/hooks';
import { ChipsList } from '../../../../../components';

const StepItem = ({
  activityInformation,
  overwriteActivityInformation,
  reviewTree = {},
  step,
  language,
}) => {
  const [stepInformation, setStepInformation] = useState(step);
  const [openModalChangeType, setOpenModalChangeType] = useState(false);
  const permissions = usePermissions(language);

  if (!step) return <></>;

  const primaryText = stepInformation.name;
  const secondaryText = `${stepInformation.wrapper.idInteger} / ${stepInformation.idInteger} - ${stepInformation.wrapper.type.name}: ${stepInformation.description}`;

  return (
    <>
      <ListItem>
        <ListItemText primary={primaryText} secondary={secondaryText} />
        <ChipsList tree={reviewTree} />
        <Actions
          permissions={permissions}
          stepInformation={stepInformation}
          setOpenModalChangeType={setOpenModalChangeType}
        />
      </ListItem>
      <Modals
        stepInformation={stepInformation}
        setStepInformation={setStepInformation}
        activityInformation={activityInformation}
        overwriteActivityInformation={overwriteActivityInformation}
        openModalChangeType={openModalChangeType}
        setOpenModalChangeType={setOpenModalChangeType}
      />
    </>
  );
};

const StepItemWrapped = (props) => (
  <FeatureModalsProvider>
    <StepItem {...props} />
  </FeatureModalsProvider>
);

export default StepItemWrapped;
