import { useMemo, useState } from 'react';

import { Modal } from '../../components';
import Actions from './components/Actions';
import Header from './components/Header';
import Form from './components/Form';
import { modelPlurals } from '../../shared/constants/plurals';
import { useFeatureModals, useRequest, useUX } from '../../shared/hooks';

const adapter = (data, model) => {
  const key = modelPlurals[model];
  const { _id, idInteger } = data;
  const adapted = data[key].map((element) => ({
    ...element,
    wrapper: {
      _id,
      idInteger,
    },
  }));
  return {
    ...data,
    [key]: adapted,
  };
};

const initialState = {
  name: '',
  description: '',
};

const ModalCreateElement = ({
  callback = () => null,
  payload,
  model = '',
  options = [],
  disabledOptions = [],
}) => {
  const api = useRequest();
  const {
    data: { loading },
    actions: { alert, setLoading },
  } = useUX();
  const {
    state: { isCreateElementOpened },
  } = useFeatureModals();

  const [selected, setSelected] = useState([]);
  const [values, setValues] = useState(initialState);

  const disabledOptionsIds = useMemo(
    () => disabledOptions.map(({ _id }) => _id),
    [disabledOptions]
  );

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    if (options.length && !selected.length) return;
    setLoading(true);
    const endpoint = `/wrapper-${model}`;
    api
      .post(endpoint, {
        ...values,
        ...payload,
        type: selected,
      })
      .then(({ data }) => {
        callback(adapter(data, model));
        setValues(initialState);
      })
      .catch(({ response }) =>
        alert.openError(response?.data?.message || `Error creating ${model}`)
      )
      .finally(() => setLoading(false));
  };

  const title = `Create new ${model}`;
  const subtitle = `Fill in the form to create a new ${model}`;

  return (
    <Modal open={isCreateElementOpened}>
      <Header title={title} subtitle={subtitle} />
      <Form
        handleChange={handleChange}
        selected={selected}
        setSelected={setSelected}
        values={values}
        options={options}
        disabledOptionsIds={disabledOptionsIds}
      />
      <Actions
        disabled={loading}
        onSubmit={handleSubmit}
        onClose={() => callback()}
      />
    </Modal>
  );
};

export default ModalCreateElement;
