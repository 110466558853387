import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { Editor } from '../../features';

const Topic = () => {
  const { id } = useParams();
  return (
    <Container maxWidth="xl">
      <Editor id={id} parentName="topic" />
    </Container>
  );
};

export default Topic;
