import React from 'react';
import { Box } from '@material-ui/core';
import { WarningChip } from '..';
import { countReviews } from '../../shared/helpers/array';
import styles from '../../assets/styles';

const ChipsList = ({ tree = {} }) => {
  const classes = styles();

  const chips = React.useMemo(() => {
    let chipContents = 0,
      chipActivities = 0,
      chipSteps = 0;
    const hasContents = Object.keys(tree).includes('contents');
    if (hasContents) chipContents = countReviews(tree.contents);
    const hasActivities = Object.keys(tree).includes('activities');
    if (hasActivities) {
      chipActivities = countReviews(tree.activities);
      const steps = tree.activities.map(({ steps }) => steps).flat();
      chipSteps = countReviews(steps);
    } else {
      chipSteps = countReviews(tree.steps);
    }
    return {
      chipContents,
      chipActivities,
      chipSteps,
      current: tree.review,
    };
  }, [tree]);

  const { chipContents, chipActivities, chipSteps, current } = chips;

  return (
    <Box className={`${classes.flex} ${classes.alignCenter}`}>
      {current && <WarningChip label={`Review information`} />}
      {chipActivities > 0 && (
        <WarningChip label={`Activities to review: ${chipActivities}`} />
      )}
      {chipSteps > 0 && <WarningChip label={`Steps to review: ${chipSteps}`} />}
      {chipContents > 0 && (
        <WarningChip label={`Contents to review: ${chipContents}`} />
      )}
    </Box>
  );
};

export default ChipsList;
