export const swapElementsByIndex = (array = [], index = 0, amount = -1) => {
  const actionElement = array[index];
  const swappedElement = array[index + amount];
  const shift = Math.max(amount, 0);
  return [
    ...array.slice(0, index + shift - 1),
    amount > 0 ? swappedElement : actionElement,
    amount > 0 ? actionElement : swappedElement,
    ...array.slice(index + shift + 1),
  ];
};

export const countReviews = (array = []) =>
  array.reduce((sum, element) => sum + +element.review, 0);
