import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  box: {
    display: 'inline-flex',
    marginTop: 20,
    '& > * + *': {
      marginLeft: 10,
    },
  },
}));

const InlineActions = ({ children }) => {
  const classes = styles();
  return <Box className={classes.box}>{children}</Box>;
};

export default InlineActions;
