import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  styled,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useRequest, useUser, useUX } from '../../shared/hooks';
import CardModule from './components/CardModule';
import { ModalCreateElement } from '../../features';
import styles from '../../assets/styles';

const HomeUser = () => {
  const history = useHistory();
  const api = useRequest();
  const {
    state: { isAdmin },
  } = useUser();
  const {
    actions: { setLoading, alert },
  } = useUX();
  const classes = styles();

  const [openedModal, setOpenedModal] = React.useState(false);
  const [modules, setModules] = React.useState(null);

  const openModule = (id) => {
    history.push(`/module/${id}`);
  };

  const handleCloseModal = (data = null) => {
    if (data) {
      alert.openSuccess('New module created successfully!');
      const id = data.modules[0]._id;
      openModule(id);
    }
    setOpenedModal(false);
  };

  React.useEffect(() => {
    setLoading(true);
    api
      .get('/module')
      .then(({ data }) => setModules(data))
      .catch(({ response }) => {
        alert.openError(response?.data?.message);
      })
      .finally(() => setLoading(false));
  }, [alert, api, setLoading]);
  if (!modules) return <></>;

  return (
    <Container>
      <Grid container spacing={2}>
        {modules.map((course) => (
          <Grid key={course.id} item md={4} sm={6} xs={12}>
            <CardModule
              openModuleFn={() => openModule(course._id)}
              review={course.review}
            >
              <Typography variant="h5">{course.name}</Typography>
              <Typography variant="subtitle1">{course.description}</Typography>
              <Typography className={classes.captionBlock} variant="body2">
                Language: {`${course.language.name}\n`}
              </Typography>
              <Typography className={classes.captionBlock} variant="body2">
                Last updated: {course.updatedAt}
              </Typography>
            </CardModule>
          </Grid>
        ))}
        {isAdmin && (
          <Grid item md={4} xs={6}>
            <AddNewModule
              className={`${classes.containerDashed} ${classes.pointer}`}
              style={{
                borderWidth: 5,
              }}
              onClick={() => setOpenedModal(true)}
            >
              <CardContent>
                <Typography variant="h5">Add a new module</Typography>
                <Typography variant="subtitle1">
                  Add a new module to the database
                </Typography>
              </CardContent>
            </AddNewModule>
          </Grid>
        )}
      </Grid>
      {openedModal && (
        <ModalCreateElement
          fields={['name', 'description']}
          title="Create new module"
          subtitle="Fill in the form to create a new module"
          endpoint="/wrapper-module"
          callback={handleCloseModal}
        />
      )}
    </Container>
  );
};

export default HomeUser;

const CustomCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
});

const AddNewModule = styled(CustomCard)({
  borderWidth: 5,
  border: '2px dashed rgba(255, 255, 255, .25)',
  cursor: 'pointer',
});
