import React from 'react';
import { ListItem, ListItemText, styled } from '@material-ui/core';
import styles from '../../../../assets/styles';

const AddNewButton = ({ children, onClick = () => null }) => {
  const classes = styles();
  return (
    <ListButton
      className={`${classes.containerDashed} ${classes.pointer}`}
      onClick={onClick}
    >
      <ListItemText primary={children} />
    </ListButton>
  );
};

export default AddNewButton;

const ListButton = styled(ListItem)({
  marginTop: 10,
  marginBottom: 10,
  marginLeft: -5,
  width: 'calc(100% + 10px)',
  borderWidth: 5,
});
