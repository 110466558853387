import AddNewButton from '../../AddNewButton';
import ModelList from '../../ModelList';
import ActivityItem from '../ActivityItem';
import { useFeatureModals } from '../../../../../shared/hooks';

const ActivitiesList = ({
  open: isOpen,
  reviewTree = [],
  showAddButton,
  topicInformation,
  overwriteTopicInformation,
  language,
}) => {
  const {
    actions: { open },
  } = useFeatureModals();

  const activities = topicInformation?.activities;

  if (!activities) return <></>;

  return (
    <ModelList open={isOpen}>
      {activities.map((activity, index) => (
        <ActivityItem
          key={activity._id}
          activity={activity}
          reviewTree={reviewTree[index]}
          topicInformation={topicInformation}
          overwriteTopicInformation={overwriteTopicInformation}
          language={language}
        />
      ))}
      {showAddButton && (
        <AddNewButton onClick={() => open('createElement')}>
          Create new activity
        </AddNewButton>
      )}
    </ModelList>
  );
};

export default ActivitiesList;
