import React from 'react';
import { Box, Button, Typography, styled } from '@material-ui/core';
import { useUX } from '../../../../shared/hooks';
import { processImage } from '../../../../shared/helpers/images';

const MAX_SIZE = 500_000;

const ImageFieldComponent = ({
  content: incomingContent = {},
  index = 0,
  handleChange = () => {},
  handleDelete = () => {},
  readOnly = false,
}) => {
  const [content, setContent] = React.useState(incomingContent);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const inputFileRef = React.useRef(null);

  const {
    actions: { alert },
  } = useUX();

  const editable = !!handleDelete;

  const trigger = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = async (event) => {
    const [file] = event.target.files;
    if (file.size > MAX_SIZE) {
      alert.openError(
        'This image is bigger than 500kB. Try compressing it first and then upload again.'
      );
      return;
    }
    processImage(file)
      .then((image) => {
        handleChange({ target: { value: image.result } }, index);
        setContent({
          value: image.result,
        });
      })
      .catch((e) => {
        console.error(e);
        alert.openError('Error adding image');
      });
  };

  return (
    <ImageFieldBox
      onMouseEnter={() => setShowOverlay(true)}
      onMouseLeave={() => setShowOverlay(false)}
    >
      {!readOnly && showOverlay && (
        <ImageFieldOverlay>
          <ImageFieldOverlayText variant="h6">
            Change current image
          </ImageFieldOverlayText>
          <ImageFieldOverlayButton
            color="primary"
            variant="contained"
            onClick={trigger}
          >
            Upload new image
          </ImageFieldOverlayButton>
          {editable && (
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDelete}
            >
              Delete image
            </Button>
          )}
          <input
            type="file"
            accept=".jpg,.jpeg,.png"
            style={{ display: 'none' }}
            ref={inputFileRef}
            onChange={handleFileChange}
          />
        </ImageFieldOverlay>
      )}
      <img
        id={content.id}
        style={{ maxWidth: '100%', margin: '0 auto' }}
        src={content.value}
        alt={content.id}
        data-content
      />
    </ImageFieldBox>
  );
};

export default ImageFieldComponent;

const ImageFieldBox = styled(Box)({
  maxWidth: '100%',
  padding: 20,
  position: 'relative',
});

const ImageFieldOverlay = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .85)',
});

const ImageFieldOverlayText = styled(Typography)({
  color: '#fff',
  marginBottom: 10,
  fontSize: '1rem',
});

const ImageFieldOverlayButton = styled(Button)({
  marginBottom: 10,
});
