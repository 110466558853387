import { createContext, useMemo, useState, useCallback } from 'react';
import {
  Backdrop,
  CircularProgress,
  Fade,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTimeout } from '../hooks';

export const UXContext = createContext({});

const TIMEOUTS = {
  close: 200,
  open: 5000,
};

const styles = makeStyles(() => ({
  alert: {
    position: 'fixed',
    top: 40,
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: 500,
    zIndex: 1000,
    wordBreak: 'break-word',
  },
  loading: {
    zIndex: 9999,
  },
  backdrop: {
    background: 'transparent',
    zIndex: 998,
  },
}));

const defaultMessages = {
  success: 'Success!',
  error: 'Unknown error. Please check logs and try again.',
};

export const UXProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState(null);

  const classes = styles();

  const setLoadingBackdrop = useCallback((state) => {
    setBackdrop(state);
    setLoading(state);
  }, []);

  const [startAlertTimer, cancelAlertTimer] = useTimeout({
    callback: () => setShowAlert(false),
    timeout: TIMEOUTS.open,
  });

  const openAlert = useCallback(
    (message) => {
      setMessage(message);
      setShowAlert(true);
      startAlertTimer();
    },
    [startAlertTimer]
  );

  const closeAlert = () => {
    cancelAlertTimer();
    setShowAlert(false);
  };

  const alert = useMemo(
    () => ({
      openError: (message) => {
        openAlert(message || defaultMessages.error);
        setSeverity('error');
      },
      openSuccess: (message) => {
        openAlert(message || defaultMessages.success);
        setSeverity('success');
      },
    }),
    [openAlert, setSeverity]
  );

  return (
    <UXContext.Provider
      value={{
        data: {
          loading,
        },
        actions: {
          alert,
          setLoading: setLoadingBackdrop,
        },
      }}
    >
      <Backdrop open={backdrop} className={classes.backdrop}>
        {loading && (
          <CircularProgress color="inherit" className={classes.loading} />
        )}
      </Backdrop>
      <Fade in={showAlert} className={classes.alert}>
        <Alert
          onClose={closeAlert}
          variant="filled"
          severity={severity || 'error'}
        >
          {message}
        </Alert>
      </Fade>

      {children}
    </UXContext.Provider>
  );
};
