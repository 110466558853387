import { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, styled } from '@material-ui/core';

import { Modal } from '../../components';
import { useFeatureModals, useRequest, useUX } from '../../shared/hooks';

const ModalEditInformation = ({
  callback = () => null,
  isMain = false,
  parent = {},
  parentName = '',
}) => {
  const api = useRequest();
  const {
    data: { loading },
    actions: { alert, setLoading },
  } = useUX();
  const [values, setValues] = useState({
    name: parent.name,
    description: parent.description,
  });
  const {
    state: { isEditInformationOpened },
  } = useFeatureModals();

  useEffect(() => {
    setValues(
      isEditInformationOpened
        ? {
            name: parent.name,
            description: parent.description,
          }
        : {
            name: '',
            description: '',
          }
    );
  }, [isEditInformationOpened, parent]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    api
      .put(`${parentName}/${parent._id}`, values)
      .then(({ data }) => callback(data))
      .catch((e) => {
        console.log(e);
        alert.openError(
          e?.response?.data?.message || 'Error editing information'
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal open={isEditInformationOpened}>
      <Typography variant="h5">{`Edit information of ${parentName}: ${parent.name}`}</Typography>
      {isMain && (
        <Typography variant="caption">
          This will trigger review across all translations.
        </Typography>
      )}
      <Container>
        <TextField
          name="name"
          label="Name"
          fullWidth
          onChange={handleChange}
          value={values.name}
        />
        <TextField
          name="description"
          label="Description"
          fullWidth
          onChange={handleChange}
          value={values.description}
        />
      </Container>
      <Actions>
        <Button onClick={handleSubmit} disabled={loading}>
          Submit
        </Button>
        <Button onClick={() => callback()} disabled={loading}>
          Close
        </Button>
      </Actions>
    </Modal>
  );
};

export default ModalEditInformation;

const Container = styled(Box)({
  padding: '20px 0',
});

const Actions = styled(Box)({
  display: 'flex',
});
