import React from 'react';
import { Box, IconButton, Fade, Popper, makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';

const styles = makeStyles({
  addNewContent: {
    width: 'calc(100% - 20px)',
    borderWidth: 5,
    border: '2px dashed rgba(0, 0, 0, .25)',
    cursor: 'pointer',
    fontSize: 24,
    opacity: 1,
  },
  placeholder: {
    height: 16,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    marginTop: -12,
    marginBottom: -4,
  },
  buttonWrapper: {
    height: 46,
    width: 46,
    position: 'absolute',
    left: 'calc(50% - 23px)',
    bottom: -23,
    backgroundColor: 'white',
    borderRadius: '50%',
  },
  button: {
    height: '100%',
  },
});

const AddNewContent = ({ position = 0, onClick = () => null }) => {
  const classes = styles();
  const [popperAnchor, setPopperAnchor] = React.useState(null);
  const containerRef = React.useRef();

  const openPopover = (event) => {
    setPopperAnchor(event.currentTarget);
  };

  const closePopover = () => {
    setPopperAnchor(null);
  };

  return (
    <Box
      ref={containerRef}
      className={classes.placeholder}
      onMouseEnter={openPopover}
      onMouseLeave={closePopover}
      style={{
        zindex: 2998,
      }}
    >
      <Popper
        anchorEl={popperAnchor}
        open={!!popperAnchor}
        placement="top"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box className={classes.buttonWrapper}>
              <IconButton
                className={classes.button}
                onClick={() => onClick(position + 1)}
              >
                <Add />
              </IconButton>
            </Box>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export default AddNewContent;
