import { useCallback, useEffect, useMemo } from 'react';
import {
  useLazyLoadedList,
  useRequest,
  useUX,
} from '../../../../../shared/hooks';

const useTopicItem = ({ id, setTopic }) => {
  const api = useRequest();
  const {
    actions: { alert },
  } = useUX();
  const service = useCallback(
    () =>
      api
        .get(`/topic/${id}`)
        .then(({ data }) => data)
        .catch(({ response }) => {
          alert.openError(response?.data?.message || 'Error fetching topic');
        }),
    [alert, api, id]
  );

  const { isOpen, Icon, trigger, value } = useLazyLoadedList({
    service,
  });

  useEffect(() => {
    if (value) setTopic(value);
  }, [setTopic, value]);

  return useMemo(
    () => ({
      isOpen,
      Icon,
      trigger,
    }),
    [Icon, isOpen, trigger]
  );
};

export default useTopicItem;
