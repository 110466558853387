import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLocalStorage, useUser } from '../../shared/hooks';

const LoginCallback = () => {
  const history = useHistory();
  const location = useLocation();
  const statePath = location?.state?.from?.pathname;
  const {
    state: { isLoading, isLoggedIn },
  } = useUser();
  const [navigateTo, setNavigateTo, clearNavigateTo] = useLocalStorage(
    'redirection_url',
    statePath
  );

  useEffect(() => {
    if (statePath) setNavigateTo(statePath);
    return clearNavigateTo;
  }, [clearNavigateTo, setNavigateTo, statePath]);

  useEffect(() => {
    if (isLoading) return;
    if (!isLoggedIn) return history.push('/');
    history.push(navigateTo ?? '/home');
  }, [history, isLoggedIn, isLoading, navigateTo]);

  return <></>;
};

export default LoginCallback;
