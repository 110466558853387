import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography, styled } from '@material-ui/core';

const LanguageList = ({ currentLanguage, siblings }) => (
  <StyledList>
    <CurrentLanguageText variant="body2">
      {`Available translations: ${currentLanguage.name}`}
    </CurrentLanguageText>
    {siblings?.map(({ _id, language: { name } }) => (
      <LanguageLink key={_id}>
        <Link component={RouterLink} to={`/module/${_id}`}>
          {name}
        </Link>
      </LanguageLink>
    ))}
  </StyledList>
);

export default LanguageList;

const StyledList = styled(Box)({
  display: 'flex',
  width: '100%',
  lineBreak: 'normal',
});

const CurrentLanguageText = styled(Typography)({
  marginRight: 5,
});

const LanguageLink = styled(Link)({
  marginRight: 5,
});
