import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useRequest, useUser, useUX } from '../../shared/hooks';
import { mapIdsToEntity } from '../../shared/helpers/api';
import TableUsers from './components/TableUsers';
import ModalUser from './components/ModalUser';
import ModalLanguage from './components/ModalLanguage';
import styles from './styles';

const AdminPage = () => {
  const history = useHistory();
  const api = useRequest();
  const {
    data: { user },
  } = useUser();
  const {
    data: { loading },
    actions: { alert, setLoading },
  } = useUX();

  const classes = styles();

  const [refetch, setRefetch] = React.useState(false);
  const [languages, setLanguages] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [openRegisterModal, setOpenRegisterModal] = React.useState(false);
  const [openNewLanguageModal, setOpenNewLanguageModal] = React.useState(false);

  React.useEffect(() => {
    if (!user.admin) {
      history.push(!!user ? '/home' : '/');
    }
  }, [history, user]);

  React.useEffect(() => {
    if (languages.length === 0) {
      api
        .get('/language')
        .then(({ data }) => setLanguages(data))
        .catch(() =>
          alert.openError('Error fetching languages. Please try again later')
        );
    }
  }, [alert, api, languages.length]);

  React.useEffect(() => {
    if (roles.length === 0) {
      api
        .get('/role')
        .then(({ data }) => setRoles(data))
        .catch(() =>
          alert.openError('Error fetching roles. Please try again later')
        );
    }
  }, [alert, api, roles.length]);

  React.useEffect(() => {
    if (
      (!loading && !users.length && roles.length && languages.length) ||
      refetch
    ) {
      setRefetch(false);
      setLoading(true);
      api
        .get('/user/all')
        .then(({ data: users }) => {
          setUsers(
            users.map((user) => ({
              ...user,
              languages: mapIdsToEntity(user.languages, languages),
              roles: mapIdsToEntity(user.roles, roles),
            }))
          );
        })
        .catch((e) => {
          console.log(e);
          alert.openError('Error fetching users. Please try again later');
        })
        .finally(() => setLoading(false));
    }
  }, [
    alert,
    api,
    languages,
    languages.length,
    loading,
    refetch,
    roles,
    roles.length,
    setLoading,
    users.length,
  ]);

  const handleModalAction = (data = null) => {
    if (data) {
      setRefetch(true);
    }
    setOpenRegisterModal(false);
  };

  const handleLanguageAction = (data = null) => {
    if (data) {
      setLanguages([...languages, data]);
    }
    setOpenNewLanguageModal(false);
  };

  if (!users.length) return <></>;

  return (
    <>
      <TableUsers roles={roles} languages={languages} usersPayload={users} />
      <Box className={classes.actionsList}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpenRegisterModal(true)}
        >
          Register new user
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpenNewLanguageModal(true)}
        >
          Add new language
        </Button>
      </Box>
      {openRegisterModal && (
        <ModalUser
          roles={roles}
          languages={languages}
          callback={handleModalAction}
        />
      )}
      {openNewLanguageModal && (
        <ModalLanguage languages={languages} callback={handleLanguageAction} />
      )}
    </>
  );
};

export default AdminPage;
