import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          minHeight: '100vh',
        },
        body: {
          minHeight: '100vh',
        },
        '#root': {
          minHeight: '100vh',
          height: '100%',
        },
      },
    },
  },
  palette: {
    text: {
      primary: '#b2b49c',
      secondary: '#eee',
      disabled: '#aaa',
      warning: '#222',
    },
    primary: {
      main: '#f4f12a',
      contrastText: '#222',
    },
    secondary: {
      main: '#ccd44d',
    },
    error: {
      main: red.A400,
    },
    background: {
      paper: '#232323',
    },
  },
});

export default theme;
