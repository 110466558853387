import { Modal } from '../../components';
import Actions from './components/Actions';
import Header from './components/Header';
import { useFeatureModals, useRequest, useUX } from '../../shared/hooks';

const ModalDeleteElement = ({
  callback = () => null,
  id,
  name,
  elementName,
}) => {
  const api = useRequest();
  const {
    data: { loading },
    actions: { alert, setLoading },
  } = useUX();
  const {
    state: { isDeleteElementOpened },
  } = useFeatureModals();

  const handleSubmit = () => {
    setLoading(true);
    const endpoint = `/wrapper-${elementName}/${id}`;
    api
      .delete(endpoint)
      .then(({ data }) => callback(data))
      .catch(({ response }) => alert.openError(response?.data?.message))
      .finally(() => setLoading(false));
  };

  const title = `Delete ${elementName} ${name}`;
  const subtitle =
    'WARNING: this will delete this element, all its translations and all its nested children';

  return (
    <Modal open={isDeleteElementOpened}>
      <Header title={title} subtitle={subtitle} />
      <Actions
        disabled={loading}
        onSubmit={handleSubmit}
        onClose={() => callback()}
      />
    </Modal>
  );
};

export default ModalDeleteElement;
