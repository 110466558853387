import React from 'react';
import { Backdrop, Paper, styled } from '@material-ui/core';

const Modal = ({ children, open = false, style = {} }) => {
  return (
    <Wrapper open={open}>
      <Surface style={style}>{children}</Surface>
    </Wrapper>
  );
};

export default Modal;

const Surface = styled(Paper)({
  padding: '40px 25px',
  zIndex: 991,
});

const Wrapper = styled(Backdrop)({
  zIndex: 990,
  backgroundColor: 'rgba(0, 0, 0, .75)',
});
