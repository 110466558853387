import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { Editor } from '../../features';

const Step = () => {
  const { id } = useParams();
  return (
    <Container maxWidth="xl">
      <Editor id={id} parentName="step" />
    </Container>
  );
};

export default Step;
