import AddNewButton from '../../AddNewButton';
import ModelList from '../../ModelList';
import StepItem from '../Stepitem';
import { useFeatureModals } from '../../../../../shared/hooks';
import useModule from '../../../provider';

const StepsList = ({
  activityInformation,
  open: isOpen,
  overwriteActivityInformation,
  reviewTree = [],
  showAddButton,
  language,
}) => {
  const {
    actions: { open },
  } = useFeatureModals();
  const {
    data: { stepTypes },
  } = useModule();

  const steps = activityInformation?.steps;

  if (!steps) return <></>;

  const availableStepTypes = steps.length < stepTypes.length;

  return (
    <ModelList open={isOpen}>
      {steps.map((step, index) => (
        <StepItem
          key={step._id}
          step={step}
          reviewTree={reviewTree[index]}
          activityInformation={activityInformation}
          overwriteActivityInformation={overwriteActivityInformation}
          language={language}
        />
      ))}
      {showAddButton && availableStepTypes && (
        <AddNewButton onClick={() => open('createElement')}>
          Create new step
        </AddNewButton>
      )}
    </ModelList>
  );
};

export default StepsList;
