import { useHistory } from 'react-router-dom';
import { Delete, Edit, Sort, Translate } from '@material-ui/icons';

import { IconButton } from '../../../../../../components';

import useModule from '../../../../provider';
import { useFeatureModals, useUX } from '../../../../../../shared/hooks';

const Actions = ({ fetchTrigger, permissions, activityInformation }) => {
  const history = useHistory();
  const {
    data: { isMain },
  } = useModule();
  const {
    actions: { open },
  } = useFeatureModals();
  const {
    actions: { alert, setLoading },
  } = useUX();

  const id = activityInformation._id;
  const steps = activityInformation.steps;

  const handleSortStepsClick = () => {
    if (steps) return open('sort');
    setLoading(true);
    fetchTrigger()
      .then(() => open('sort'))
      .catch(({ response }) =>
        alert.openError(response?.data?.message || 'Error fetching steps')
      )
      .finally(() => setLoading(false));
  };

  return (
    <>
      {permissions.canSort && isMain && (
        <IconButton label="Sort Steps" onClick={handleSortStepsClick}>
          <Sort />
        </IconButton>
      )}
      {permissions.canEditInformation && (
        <IconButton
          label={isMain ? 'Create Content' : 'Translate Content'}
          onClick={() => history.push(`/activity/${id}`)}
        >
          <Translate />
        </IconButton>
      )}
      {permissions.canEditInformation && (
        <IconButton
          label="Edit Information"
          onClick={() => open('editInformation')}
        >
          <Edit />
        </IconButton>
      )}
      {permissions.canEditInformation && isMain && (
        <IconButton
          label="Delete Activity"
          variant="error"
          onClick={() => open('deleteElement')}
        >
          <Delete />
        </IconButton>
      )}
    </>
  );
};

export default Actions;
