import { Button } from '@material-ui/core';

import { InlineActions } from '../../../components';

const Actions = ({ disabled, onClose, onSubmit }) => (
  <InlineActions>
    <Button variant="contained" onClick={onSubmit} disabled={disabled}>
      Submit
    </Button>
    <Button variant="contained" onClick={onClose} disabled={disabled}>
      Close
    </Button>
  </InlineActions>
);

export default Actions;
