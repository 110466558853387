import { useEffect, useMemo, useState } from 'react';
import { useRequest, useUX } from '../../../../shared/hooks';
import services from '../../services';

const useCreateSibling = (modules = []) => {
  const api = useRequest();
  const {
    actions: { alert, setLoading },
  } = useUX();
  const { getLanguages } = useMemo(() => services(api), [api]);

  const [languages, setLanguages] = useState();
  const [formValues, setFormValues] = useState({
    language: '',
    name: '',
    description: '',
  });

  const languagesWithAvailability = useMemo(() => {
    if (!modules.length || !languages) return [];
    const usedLanguages = modules.map(({ language: { _id } }) => _id);
    return languages.map((language) => ({
      ...language,
      available: !usedLanguages.includes(language._id),
    }));
  }, [languages, modules]);

  useEffect(() => {
    if (languages) return;
    setLoading(true);
    getLanguages()
      .then((languagesObjects) => setLanguages(languagesObjects))
      .catch(() => {
        alert.openError('Error while fetching available languages');
      })
      .finally(() => setLoading(false));
  }, [alert, getLanguages, languages, setLoading]);

  return useMemo(
    () => ({
      data: {
        formValues,
        languages,
        languagesWithAvailability,
      },
      actions: {
        setFormValues,
      },
    }),
    [formValues, languages, languagesWithAvailability]
  );
};

export default useCreateSibling;
