import { useEffect, useState } from 'react';

import { Modal } from '../../components';
import Form from './components/Form';
import Header from './components/Header';
import { useFeatureModals, useRequest, useUX } from '../../shared/hooks';
import Actions from './components/Actions';

const ModalSort = ({ callback, isMain, items, model, title, wrapperId }) => {
  const api = useRequest();
  const {
    data: { loading },
    actions: { alert, setLoading },
  } = useUX();
  const {
    state: { isSortOpened },
  } = useFeatureModals();
  const [fieldsOrder, setFieldsOrder] = useState(items);

  useEffect(() => {
    setFieldsOrder(items);
  }, [items]);

  const handleSubmit = () => {
    setLoading(true);
    const body = fieldsOrder.map(({ wrapper: { _id } }) => _id);
    api
      .patch(`/wrapper-${model}/${wrapperId}`, body)
      .then(() => callback(fieldsOrder))
      .catch(({ response }) =>
        alert.openError(response?.data?.message || 'Error sorting elements')
      )
      .finally(() => setLoading(false));
  };

  return (
    <Modal open={isSortOpened}>
      <Header isMain={isMain} parentName={model} name={title} />
      <Form fieldsOrder={fieldsOrder} setFieldsOrder={setFieldsOrder} />
      <Actions
        disabled={loading}
        onSubmit={handleSubmit}
        onClose={() => callback()}
      />
    </Modal>
  );
};

export default ModalSort;
