import { Button } from '@material-ui/core';

import { InlineActions } from '../../../components';

const Actions = ({ disabled, onClose, onSubmit }) => (
  <InlineActions>
    <Button onClick={onSubmit} disabled={disabled}>
      Submit
    </Button>
    <Button onClick={onClose} disabled={disabled}>
      Close
    </Button>
  </InlineActions>
);

export default Actions;
