import { Typography } from '@material-ui/core';

const Timestamps = ({
  createdAt,
  idCreator: creator = {},
  updatedAt,
  idUpdater: updater,
}) => {
  const { firstName, lastName } = creator;
  const mainText =
    firstName && lastName
      ? `Created by ${firstName} ${lastName}`
      : 'Manually imported by admin';
  const secondaryText =
    createdAt === updatedAt
      ? ''
      : `Last updated ${
          updater ? `by ${updater.firstName} ${updater.lastName}` : ''
        } at ${new Date(updatedAt).toLocaleDateString()} ${new Date(
          updatedAt
        ).toLocaleTimeString()}`;

  return (
    <>
      <Typography variant="body2">{mainText}</Typography>
      <Typography variant="body2">{secondaryText}</Typography>
    </>
  );
};

export default Timestamps;
