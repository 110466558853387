export const getMimetypeFromBase64 = (base64 = '') =>
  base64.split(',')[0].match(/:(.*?);/)[1];
export const generateRandomFilename = (mimeType = 'image/jpeg') => {
  const [, extension] = mimeType.split('/');
  const timestamp = Date.now();
  const [, random] = Math.random().toString().split('.');
  return `${timestamp}${random}.${extension}`;
};

export const convertImageToBase64 = (image) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const checkImageDimension = ({
  image = null,
  maxHeight = 10000,
  maxWidth = 10000,
}) =>
  new Promise((resolve, reject) => {
    if (!image) reject('Image cannot be empty');
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = (event) => {
      const imageBuilder = new Image();
      imageBuilder.src = event.target.result;
      imageBuilder.onload = function () {
        const height = this.height;
        const width = this.width;
        const errors = [];
        if (height > maxHeight) errors.push('height');
        if (width > maxWidth) errors.push('width');
        if (errors.length) reject(errors);
        resolve();
      };
    };
  });

export const redimensionImage = ({
  image = null,
  targetHeight = 0,
  targetWidth = 0,
  canvasRef = null,
  errors = [],
}) =>
  new Promise((resolve, reject) => {
    if (!image) reject('Image cannot be empty');
    if (!canvasRef) reject('Missing canvas');
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = (event) => {
      const imageBuilder = new Image();
      imageBuilder.src = event.target.result;
      imageBuilder.onload = function () {
        const aspectRatio = this.width / this.height;
        let width, height;
        if (targetWidth > 0) {
          width = targetWidth;
          height = targetWidth / aspectRatio;
        } else if (targetHeight > 0) {
          height = targetHeight;
          width = targetHeight * aspectRatio;
        }
        canvasRef.height = height;
        canvasRef.width = width;
        const ctx = canvasRef.getContext('2d');
        ctx.drawImage(imageBuilder, 0, 0, width, height);
        const dataUrl = canvasRef.toDataURL('image/png');
        resolve(dataUrl);
      };
    };
  });

export const processImage = (image) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(reader);
    };
    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsDataURL(image);
  });

export const convertBase64ToImageFile = (base64) =>
  new Promise(async (resolve, reject) => {
    try {
      const mimeType = getMimetypeFromBase64(base64);
      const img = await fetch(base64);
      const buffer = await img.arrayBuffer();
      const fileName = generateRandomFilename(mimeType);
      resolve(new File([buffer], fileName, { type: mimeType }));
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
