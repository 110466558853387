import { useMemo } from 'react';
import {
  ModalCreateElement,
  ModalDeleteElement,
  ModalEditInformation,
  ModalSort,
} from '../../../../../../features';
import { useFeatureModals, useUX } from '../../../../../../shared/hooks';
import useModule from '../../../../provider';

const adaptNewStep = ({ wrapper, wrapperType, ...step }, stepTypes) => ({
  ...step,
  wrapper: {
    ...wrapper,
    type: stepTypes.find(({ _id }) => _id === wrapperType),
  },
});

const Modals = ({
  activityInformation,
  setActivityInformation,
  overwriteTopicInformation,
  topicInformation,
}) => {
  const {
    data: { isMain, moduleInformation, stepTypes },
  } = useModule();
  const {
    actions: { close },
  } = useFeatureModals();
  const {
    actions: { alert },
  } = useUX();

  const steps = activityInformation?.steps;

  const disabledOptionsIds = useMemo(
    () => steps?.map(({ wrapper: { type } }) => type) ?? [],
    [steps]
  );

  if (!activityInformation) return <></>;

  const languageId = moduleInformation.language._id;
  const name = activityInformation.name;
  const wrapperActivityId = activityInformation.wrapper._id;

  const editInformationCallback = (data) => {
    close('editInformation');
    if (!data) return;
    const { name, description, updatedAt } = data;
    alert.openSuccess('Activity information updated successfully!');
    setActivityInformation((activity) => ({
      ...activity,
      name,
      description,
      updatedAt,
    }));
  };

  const createElementCallback = (data) => {
    close('createElement');
    if (!data) return;
    const newStep = data.steps.find(({ language }) => language === languageId);
    const { steps } = activityInformation;
    const newSteps = [...steps, adaptNewStep(newStep, stepTypes)];
    setActivityInformation((prev) => ({
      ...prev,
      steps: newSteps,
    }));
  };

  const sortCallback = (data) => {
    close('sort');
    if (!data) return;
    alert.openSuccess('Steps reordered successfully');
    setActivityInformation((prev) => ({
      ...prev,
      steps: data,
    }));
  };

  const deleteElementCallback = (data) => {
    close('deleteElement');
    if (!data) return;
    alert.openSuccess(
      `Activity ${name}, all its translations (if any) and all its children (if any) were successfully deleted.`
    );
    overwriteTopicInformation({
      activities: topicInformation.activities.filter(
        ({ _id }) => _id !== activityInformation._id
      ),
    });
  };

  return (
    <>
      <ModalEditInformation
        isMain={isMain}
        parent={activityInformation}
        parentName="activity"
        callback={editInformationCallback}
      />
      <ModalCreateElement
        payload={{ wrapperActivity: wrapperActivityId }}
        model="step"
        options={stepTypes}
        disabledOptions={disabledOptionsIds}
        callback={createElementCallback}
      />
      <ModalSort
        isMain={isMain}
        items={steps}
        model="activity"
        title={name}
        wrapperId={wrapperActivityId}
        callback={sortCallback}
      />
      <ModalDeleteElement
        callback={deleteElementCallback}
        id={wrapperActivityId}
        name={name}
        elementName="activity"
      />
    </>
  );
};

export default Modals;
