import React from 'react';
import { Button, Grid, Typography, styled, useTheme } from '@material-ui/core';

import { Surface } from '../../components';
import LeftSide from './components/LeftSide';
import Field from './components/Field';
import HeaderFields from './components/Field/HeaderFields';
import AddNewContent from './components/AddNewContent';
import { capitalize } from '../../shared/helpers/strings';
import { useRequest, useUX } from '../../shared/hooks';
import useContents from './hook';
import { swapElementsByIndex } from '../../shared/helpers/array';
import { contentsAdapter, updateImagesOnDom } from './helpers';

import blankImage from '../../assets/img/blank.png';
import styles from '../../assets/styles';

const Editor = ({ id = '', parentName = '' }) => {
  const api = useRequest();
  const formRef = React.useRef(null);
  const {
    data: { loading },
    actions: { alert, setLoading },
  } = useUX();
  const theme = useTheme();
  const classes = styles();

  const [insertionPosition, setInsertionPosition] = React.useState(null);
  const [contents, setContents] = React.useState([]);
  const {
    fullContentTypes,
    contentTypes,
    entity,
    mainEntity,
    handleContentsUpdate,
  } = useContents({
    id,
    parentName,
  });
  const [headerContents, initialContents] = React.useMemo(
    () =>
      Array.isArray(entity?.contents)
        ? [entity.contents.slice(0, 2), entity.contents.slice(2)]
        : [[], []],
    [entity?.contents]
  );
  const imageType = React.useMemo(
    () => fullContentTypes.find(({ name }) => name === 'Image')?._id,
    [fullContentTypes]
  );
  const language = entity?.language;
  const main = React.useMemo(() => language?.main, [language?.main]);

  React.useEffect(() => {
    setContents(initialContents);
  }, [initialContents]);

  if (!entity || !contentTypes) return <></>;

  const handleOrderChange = (index = 0, amount = -1) => {
    const newContents = swapElementsByIndex(contents, index, amount);
    setContents(newContents);
  };

  const handleChange = (event, index) => {
    const newContents = [...contents];
    newContents[index] = {
      ...newContents[index],
      content: event.target.value,
    };
    setContents(newContents);
  };

  const handleDelete = (index) => {
    const newContents = [
      ...contents.slice(0, index),
      ...contents.slice(index + 1),
    ];
    setContents(newContents);
  };

  const handleAddContent = (type = '', index = 0) => {
    setContents([
      ...contents.slice(0, index),
      {
        language: language._id,
        wrapper: {
          type: {
            _id: type,
          },
        },
        content: type === imageType ? blankImage : 'Enter new content',
      },
      ...contents.slice(index),
    ]);
    setInsertionPosition(null);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const contentsAdapted = await contentsAdapter(api, formRef.current, [
        ...headerContents,
        ...contents,
      ]);
      await handleContentsUpdate(contentsAdapted);
      updateImagesOnDom(formRef.current, contentsAdapted);
      alert.openSuccess('Changes made successfully!');
    } catch (err) {
      console.error(err);
      alert.openError(
        err?.message || 'Something went wrong, please check the console'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleAddNewContentClick = (position) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setInsertionPosition(position);
  };

  return (
    <>
      <Typography
        className={classes.alignCenter}
        style={{ color: theme.palette.primary.contrastText }}
        variant="h5"
      >
        {`Contents of ${capitalize(parentName)} - ${entity.name}`}
      </Typography>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item sm={6} style={{ height: 'auto' }}>
          <Surface>
            <LeftSide
              mainEntity={mainEntity}
              contentTypes={contentTypes}
              insertionPosition={insertionPosition}
              layoutInsertCallback={handleAddContent}
            />
          </Surface>
        </Grid>
        <Grid item sm={6} style={{ height: 'auto' }} ref={formRef}>
          <Surface id="editor-fields">
            <HeaderFields initialContents={headerContents} />
            {main && (
              <AddNewContent
                position={0}
                onClick={() => handleAddNewContentClick(0)}
              />
            )}
            {contents.map(
              (
                {
                  _id,
                  content,
                  wrapper: {
                    type: { _id: type },
                  },
                  review,
                },
                index
              ) => (
                <React.Fragment key={_id}>
                  <Field
                    content={{ id: _id, value: content }}
                    index={index}
                    type={contentTypes[type]}
                    review={review}
                    handleOrderChange={handleOrderChange}
                    handleChange={handleChange}
                    handleDelete={() => handleDelete(index)}
                    last={index === contents.length - 1}
                  />
                  {main && (
                    <AddNewContent
                      position={index}
                      onClick={(position) => handleAddNewContentClick(position)}
                    />
                  )}
                </React.Fragment>
              )
            )}
          </Surface>
          <StyledButton
            fullWidth
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={handleSubmit}
          >
            Submit
          </StyledButton>
        </Grid>
      </Grid>
    </>
  );
};

export default Editor;

const StyledButton = styled(Button)({
  marginTop: 30,
});
