import {
  Box,
  MenuItem,
  Select as MaterialSelect,
  TextField,
  styled,
} from '@material-ui/core';

const Form = ({
  disabledOptionsIds = [],
  options = [],
  selected = [],
  setSelected,
  handleChange,
  values,
}) => (
  <FormBody component="form" noValidate autoComplete="off">
    <InputText
      name="name"
      label="Name"
      fullWidth
      onChange={handleChange}
      value={values.name}
    />
    <InputText
      name="description"
      label="Description"
      fullWidth
      onChange={handleChange}
      value={values.description}
    />
    {Array.isArray(options) && !!options.length && (
      <Select
        fullWidth
        name="type"
        value={selected}
        onChange={(e) => setSelected(e.target.value)}
      >
        {options.map(({ _id, name }) => (
          <MenuItem
            key={_id}
            value={_id}
            disabled={disabledOptionsIds.includes(_id)}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
    )}
  </FormBody>
);

export default Form;

const FormBody = styled(Box)({
  marginBottom: 40,
});

const InputText = styled(TextField)({
  marginTop: 20,
});

const Select = styled(MaterialSelect)({
  marginTop: 20,
});
