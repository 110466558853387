import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  fullHeight: {
    height: '100%',
  },
  flex: {
    display: 'flex',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  alignCenter: {
    alignItems: 'center',
    textAlign: 'center',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifySpaceBetween: {
    justifyContent: 'space-between',
  },
  containerDashed: {
    border: '2px dashed rgba(255, 255, 255, .25)',
  },
  captionBlock: {
    fontSize: '.75rem',
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '.03333em',
  },
  pointer: {
    cursor: 'pointer',
  },
  contrastText: {
    color: theme.palette.primary.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.text.warning,
  },
  inlineText: {
    display: 'inline-block',
    width: 'auto',
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.primary.contrastText,
  },
  padding25: {
    padding: 25,
  },
}));
