import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Link,
  Toolbar,
  Typography,
  styled,
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { useUser } from '../../shared/hooks';
import styles from '../../assets/styles';

const Header = () => {
  const classes = styles();
  const history = useHistory();
  const {
    data: { user },
    actions: { login, logout },
    state: { isLoggedIn },
  } = useUser();

  const titleNavigation = () => {
    history.push(isLoggedIn ? '/home' : '/');
  };

  return (
    <Menu position="relative">
      <Bar>
        <Title variant="h6" onClick={titleNavigation}>
          SmartSims Content Creator
        </Title>

        {isLoggedIn && (
          <UserInformation>
            {user?.admin && (
              <Link
                style={{ marginRight: 15, color: '#222' }}
                className={classes.link}
                component={RouterLink}
                to="/admin"
              >
                Admin
              </Link>
            )}
            <Typography variant="body2">{user?.firstName}</Typography>
            <IconButton onClick={logout}>
              <AccountCircle />
            </IconButton>
          </UserInformation>
        )}
        {!isLoggedIn && (
          <Button color="inherit" onClick={login}>
            Login
          </Button>
        )}
      </Bar>
    </Menu>
  );
};

export default Header;

const Menu = styled(AppBar)({
  marginBottom: 25,
  zIndex: 800,
});

const Bar = styled(Toolbar)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const Title = styled(Typography)({
  cursor: 'pointer',
});

const UserInformation = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});
