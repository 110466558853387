import React from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@material-ui/core';
import { InlineActions, Modal } from '../../components';
import { useRequest, useUX } from '../../shared/hooks';

const ModalCreateValidation = ({
  editable = false,
  id = '',
  isNew = false,
  wrapperStepId = '',
  subtitle = '',
  name = '',
  callback = () => null,
}) => {
  const api = useRequest();
  const {
    data: { loading },
    actions: { alert, setLoading },
  } = useUX();
  const nameRef = React.useRef(null);
  const descriptionRef = React.useRef(null);
  const [validationTypes, setValidationTypes] = React.useState([]);
  const [selectedType, setSelectedType] = React.useState('');

  const textFields = React.useCallback(() => {
    if (nameRef.current && descriptionRef.current) {
      const [, nameDiv] = nameRef.current?.children;
      const [, descriptionDiv] = descriptionRef.current?.children;
      const [nameField] = nameDiv?.children;
      const [descriptionField] = descriptionDiv?.children;
      return { nameField, descriptionField };
    }

    return { nameField: null, descriptionField: null };
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const validationTypesPromise = api.get('validation/types');
        const descriptionPromise = isNew
          ? Promise.resolve()
          : api.get(`validation/${id}`);
        const [validationTypesResponse, descriptionResponse] =
          await Promise.all([validationTypesPromise, descriptionPromise]);

        setValidationTypes(validationTypesResponse.data);

        if (!isNew) {
          const { description, validationType } = descriptionResponse.data;
          const [, nameDiv] = nameRef.current.children;
          const [, descriptionDiv] = descriptionRef.current.children;
          const [nameField] = nameDiv.children;
          const [descriptionField] = descriptionDiv.children;

          nameField.value = name;
          descriptionField.value = description;
          setSelectedType(validationType);
        }
      } catch (error) {
        console.log(error);
        alert.openError(error.response?.data?.message || error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [alert, api, id, isNew, name, setLoading]);

  const handleSubmit = () => {
    setLoading(true);
    const { descriptionField, nameField } = textFields();
    const payload = {
      name: nameField.value,
      description: descriptionField.value,
      validationType: selectedType,
      wrapperStep: wrapperStepId,
    };
    if (editable || isNew) {
      const request = isNew
        ? api.post('validation', payload)
        : api.put(`validation/${id}`, payload);

      request
        .then(({ data }) =>
          callback({
            ...data,
            validationType: validationTypes.find(
              (type) => type._id === data.validationType
            ),
          })
        )
        .catch(({ response }) => {
          alert.openError(response?.data?.message);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Modal style={{ width: '60vw' }}>
      <Typography variant="h4">Create new validation</Typography>
      <Typography variant="caption">{subtitle}</Typography>
      <Form component="form" noValidate autoComplete="off">
        <InputText
          ref={nameRef}
          name="name"
          label="Name"
          fullWidth
          defaultValue={name}
          disabled={!isNew && !editable}
        />
        <FormControl style={{ width: '100%', marginTop: 20 }}>
          <InputLabel>Validation Type</InputLabel>
          <Select
            fullWidth
            name="type"
            value={selectedType}
            onChange={(event) => setSelectedType(event.target.value)}
            MenuProps={{
              disableScrollLock: true,
            }}
            disabled={!isNew && !editable}
          >
            {validationTypes.map((option) => (
              <MenuItem
                key={option._id}
                value={option._id}
                disabled={option._id === selectedType}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* Check why wrap property does not work here */}
        <InputText
          ref={descriptionRef}
          name="description"
          label="Text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          rows={15}
          disabled={!isNew && !editable}
        />
      </Form>
      <InlineActions>
        {(isNew || editable) && (
          <Button variant="contained" onClick={handleSubmit} disabled={loading}>
            Submit
          </Button>
        )}
        <Button
          variant="contained"
          onClick={() => callback()}
          disabled={loading}
        >
          Close
        </Button>
      </InlineActions>
    </Modal>
  );
};

export default ModalCreateValidation;

const Form = styled(Box)({
  marginBottom: 40,
});

const InputText = styled(TextField)({
  marginTop: 20,
});
