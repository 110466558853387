import { useContext } from 'react';
import { UserContext } from '../providers/user';

const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('Context not found');
  }

  return context;
};

export default useUser;
