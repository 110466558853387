import React from 'react';
import {
  Backdrop,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  styled,
} from '@material-ui/core';
import { useRequest, useUX } from '../../../shared/hooks';

const ModalUser = ({
  languages = [],
  roles = [],
  callback = () => {},
  initialState = null,
}) => {
  const api = useRequest();
  const {
    actions: { alert, setLoading },
  } = useUX();

  const [values, setValues] = React.useState(
    initialState || {
      id: '',
      idKeycloak: '',
      firstName: '',
      lastName: '',
      email: '',
      languages: [],
      roles: [],
      additionalInformation: '',
    }
  );

  const optionalFields = ['id', 'additionalInformation'];

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    const mandatoryFieldsFilled = !Object.entries(values).some(
      ([field, value]) =>
        !optionalFields.includes(field) &&
        (Array.isArray(value) ? value.length === 0 : value.trim() === '')
    );
    if (mandatoryFieldsFilled) {
      setLoading(true);
      if (!!initialState) {
        api
          .put(`/user/${values.id}`, values)
          .then(({ data }) => {
            alert.openSuccess('User updated successfully');
            callback(data);
          })
          .catch(({ response }) => {
            alert.openError(response?.data?.message);
          })
          .finally(() => setLoading(false));
      } else {
        api
          .post('/user', values)
          .then(({ data }) => {
            alert.openSuccess('User created successfully');
            callback(data);
          })
          .catch(({ response }) => {
            alert.openError(response?.data?.message);
          })
          .finally(() => setLoading(false));
      }
    } else {
      if (!mandatoryFieldsFilled) {
        alert.openError('Fill all mandatory fields');
        return;
      }
    }
  };

  return (
    <Backdrop
      open={true}
      style={{ zIndex: 990, backgroundColor: 'rgba(0, 0, 0, .75)' }}
    >
      <ModalSurface style={{ zIndex: 991 }}>
        <Typography variant="h4">
          {initialState ? 'Edit user' : 'Add new user'}
        </Typography>
        <Typography variant="caption">
          {initialState && (
            <>Obs: Language 'english' is implicitly registered to all users.</>
          )}
          {!initialState && (
            <>
              Register a new user.
              <br />
              Obs: this should be done AFTER the user is registered on Keycloak.
            </>
          )}
        </Typography>
        <Form component="form" noValidate autoComplete="off">
          <FormControl style={{ width: '100%' }}>
            <InputText
              name="idKeycloak"
              label="Keycloak ID"
              value={values.idKeycloak}
              onChange={handleChange}
              placeholder="Example: c6b5ba32-abcd-4fd1-a972-7d0a9e739d4c"
              fullWidth
              disabled={!!initialState}
            />
          </FormControl>
          <FormControl style={{ width: '100%' }}>
            <InputText
              name="firstName"
              label="First Name"
              value={values.firstName}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>
          <FormControl style={{ width: '100%' }}>
            <InputText
              name="lastName"
              label="Last Name"
              value={values.lastName}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>
          <FormControl style={{ width: '100%' }}>
            <InputText
              name="email"
              label="E-mail"
              value={values.email}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>
          <FormControl style={{ marginTop: 20, width: '100%' }}>
            <InputLabel>Languages</InputLabel>
            <Select
              name="languages"
              value={values.languages}
              onChange={handleChange}
              multiple
            >
              {languages.map((language) => (
                <MenuItem key={language._id} value={language._id}>
                  {language.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ marginTop: 20, width: '100%' }}>
            <InputLabel>Roles</InputLabel>
            <Select
              name="roles"
              value={values.roles}
              onChange={handleChange}
              multiple
            >
              {roles.map((role) => (
                <MenuItem key={role._id} value={role._id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ width: '100%' }}>
            <InputText
              name="additionalInformation"
              label="Other Information"
              value={values.additionalInformation}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
              rowsMax={3}
            />
          </FormControl>
        </Form>
        <Actions>
          <ModalButton
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Create
          </ModalButton>
          <ModalButton
            color="primary"
            variant="contained"
            onClick={() => callback()}
          >
            Close
          </ModalButton>
        </Actions>
      </ModalSurface>
    </Backdrop>
  );
};

export default ModalUser;

const ModalSurface = styled(Paper)({
  padding: '40px 25px',
  maxWidth: '500px',
});

const Form = styled(Box)({
  marginBottom: 20,
});

const InputText = styled(TextField)({
  marginTop: 20,
  display: 'block',
});

const Actions = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const ModalButton = styled(Button)({
  width: 'calc(50% - 5px)',
});
