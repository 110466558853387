import { useCallback, useState } from 'react';
import { ListItem, ListItemText } from '@material-ui/core';

import Actions from './components/Actions';
import FeatureModalsProvider from '../../../../../shared/providers/feature-modals';
import { usePermissions } from '../../../../../shared/hooks';
import useActivityItem from './hook';
import Modals from './components/Modals';
import StepsList from '../StepsList';
import useModule from '../../../provider';
import { ChipsList } from '../../../../../components';

const ActivityItem = ({
  activity,
  reviewTree = {},
  overwriteTopicInformation,
  topicInformation,
  language,
}) => {
  const [activityInformation, setActivityInformation] = useState(activity);
  const setActivity = useCallback(
    (info) =>
      setActivityInformation(({ wrapper }) => ({
        ...info,
        wrapper,
      })),
    []
  );
  const permissions = usePermissions(language);
  const overwriteActivityInformation = useCallback((info = {}) => {
    setActivityInformation((prev) => ({
      ...prev,
      ...info,
    }));
  }, []);

  const { isOpen, Icon, trigger } = useActivityItem({
    id: activity._id,
    setActivity,
  });
  const {
    data: { isMain },
  } = useModule();

  if (!activity) return <></>;

  const primaryText = activityInformation.name;
  const secondaryText = `${activityInformation.wrapper.idInteger} / ${activityInformation.idInteger} - ${activityInformation.description}`;

  return (
    <>
      <ListItem>
        <ListItemText primary={primaryText} secondary={secondaryText} />
        <ChipsList tree={reviewTree} />
        <Actions
          fetchTrigger={trigger}
          permissions={permissions}
          activityInformation={activityInformation}
        />
        <Icon />
      </ListItem>
      <StepsList
        open={isOpen}
        activityInformation={activityInformation}
        showAddButton={isMain && permissions.canAddNew}
        reviewTree={reviewTree.steps}
        overwriteActivityInformation={overwriteActivityInformation}
        language={language}
      />
      <Modals
        activityInformation={activityInformation}
        setActivityInformation={setActivityInformation}
        topicInformation={topicInformation}
        overwriteTopicInformation={overwriteTopicInformation}
      />
    </>
  );
};

const ActivityItemWrapped = (props) => (
  <FeatureModalsProvider>
    <ActivityItem {...props} />
  </FeatureModalsProvider>
);

export default ActivityItemWrapped;
