import {
  ModalDeleteElement,
  ModalEditInformation,
} from '../../../../../../features';
import { useFeatureModals, useUX } from '../../../../../../shared/hooks';
import useModule from '../../../../provider';
import ModalChangeType from './ModalChangeType';

const Modals = ({
  stepInformation,
  setStepInformation,
  overwriteActivityInformation,
  openModalChangeType,
  setOpenModalChangeType,
  activityInformation,
}) => {
  const {
    data: { isMain },
  } = useModule();
  const {
    actions: { close },
  } = useFeatureModals();
  const {
    actions: { alert },
  } = useUX();

  const name = stepInformation.name;
  const wrapperStepId = stepInformation.wrapper._id;

  const editInformationCallback = (data) => {
    close('editInformation');
    if (!data) return;
    const { name, description, updatedAt } = data;
    alert.openSuccess('Step information updated successfully!');
    setStepInformation((step) => ({
      ...step,
      name,
      description,
      updatedAt,
    }));
  };

  const deleteElementCallback = (data) => {
    close('deleteElement');
    if (!data) return;
    alert.openSuccess(
      `Step ${name}, all its translations (if any) and all its children (if any) were successfully deleted.`
    );
    overwriteActivityInformation({
      steps: activityInformation.steps.filter(
        ({ _id }) => _id !== stepInformation._id
      ),
    });
  };

  const changeTypeCallback = (data) => {
    setOpenModalChangeType(false);
    if (!data) return;
    alert.openSuccess(
      `Step type of ${stepInformation.name} changed to ${data.name}`
    );
    setStepInformation((step) => ({
      ...step,
      wrapper: {
        ...step.wrapper,
        type: data,
      },
    }));
  };

  return (
    <>
      <ModalEditInformation
        isMain={isMain}
        parent={stepInformation}
        parentName="step"
        callback={editInformationCallback}
      />
      <ModalDeleteElement
        callback={deleteElementCallback}
        id={wrapperStepId}
        name={name}
        elementName="step"
      />
      <ModalChangeType
        isMain={isMain}
        isOpen={openModalChangeType}
        activityInformation={activityInformation}
        callback={changeTypeCallback}
        stepInformation={stepInformation}
      />
    </>
  );
};

export default Modals;
