import { useState } from 'react';
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select as MaterialSelect,
  Typography,
  styled,
} from '@material-ui/core';

import { Flexbox, Modal } from '../../../../../../components';
import useModule from '../../../../provider';
import { useRequest, useUX } from '../../../../../../shared/hooks';

const ModalChangeType = ({
  activityInformation,
  callback,
  isMain,
  isOpen,
  stepInformation,
}) => {
  const api = useRequest();
  const {
    data: { loading },
    actions: { alert, setLoading },
  } = useUX();
  const {
    data: { stepTypes },
  } = useModule();
  const initialStepType = stepInformation.wrapper.type._id;
  const [type, setType] = useState(initialStepType);

  const unavailableStepTypes = activityInformation.steps
    .map(
      ({
        wrapper: {
          type: { _id },
        },
      }) => _id
    )
    .filter((_id) => _id !== initialStepType);

  const options = stepTypes.map(({ _id, name }) => ({
    label: name,
    value: _id,
    disabled: unavailableStepTypes.includes(_id),
  }));

  const handleSubmit = () => {
    setLoading(true);
    api
      .patch(`/wrapper-step/${stepInformation.wrapper._id}`, { type })
      .then(() => callback(stepTypes.find(({ _id }) => _id === type)))
      .catch(() => alert.openError('Error updating step type'))
      .finally(() => setLoading(false));
  };

  return (
    <Modal open={isOpen}>
      <Typography variant="h5">{`Edit step type of ${stepInformation.name}`}</Typography>
      {isMain && (
        <Typography variant="caption">
          This will change type for all of its translations.
        </Typography>
      )}
      <Container>
        <Label>Step Type</Label>
        <Select
          name="stepType"
          value={type}
          onChange={({ target: { value } }) => setType(value)}
        >
          {options.map(({ disabled, label, value }) => (
            <MenuItem key={label} value={value} disabled={disabled}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <Flexbox style={{ marginTop: 20 }}>
          <Button onClick={handleSubmit} disabled={loading}>
            Submit
          </Button>
          <Button onClick={() => callback()} disabled={loading}>
            Close
          </Button>
        </Flexbox>
      </Container>
    </Modal>
  );
};

export default ModalChangeType;

const Container = styled(Box)({
  padding: '20px 0',
});

const Label = styled(InputLabel)({
  fontSize: '1rem',
  transform: 'translate(0, 1.5px) scale(0.75)',
});

const Select = styled(MaterialSelect)({
  width: '100%',
});
