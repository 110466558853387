import {
  Accordion as MaterialAccordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  styled,
} from '@material-ui/core';
import { Delete, ExpandMore } from '@material-ui/icons';
import IconButton from '../../../components/IconButton';

const Accordion = ({ title, description, deleteHandler }) => (
  <MaterialAccordion TransitionProps={{ unmountOnExit: true }}>
    <AccordionSummary expandIcon={<ExpandMore style={{ fill: 'white' }} />}>
      <AccordionTitle>
        {title}
        <Options>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              deleteHandler();
            }}
            variant="error"
          >
            <Delete />
          </IconButton>
        </Options>
      </AccordionTitle>
    </AccordionSummary>
    <AccordionDetails>{description}</AccordionDetails>
  </MaterialAccordion>
);

export default Accordion;

const AccordionTitle = styled(Box)({
  flexGrow: 1,
  paddingTop: 5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  textAlign: 'right',
});

const Options = styled(Box)({
  flexGrow: 1,
});
