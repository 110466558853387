import { useHistory } from 'react-router-dom';
import { Delete, Edit, Translate, Tune } from '@material-ui/icons';

import { IconButton } from '../../../../../../components';

import useModule from '../../../../provider';
import { useFeatureModals } from '../../../../../../shared/hooks';

const Actions = ({ permissions, setOpenModalChangeType, stepInformation }) => {
  const history = useHistory();
  const {
    data: { isMain },
  } = useModule();
  const {
    actions: { open },
  } = useFeatureModals();

  const id = stepInformation._id;

  return (
    <>
      {permissions.canEditInformation && (
        <IconButton
          label={isMain ? 'Create Content' : 'Translate Content'}
          onClick={() => history.push(`/step/${id}`)}
        >
          <Translate />
        </IconButton>
      )}
      {permissions.canEditInformation && (
        <IconButton
          label="Edit Information"
          onClick={() => open('editInformation')}
        >
          <Edit />
        </IconButton>
      )}
      {
        permissions.canEditInformation && isMain && (
          <IconButton label="Change Type" onClick={() => setOpenModalChangeType(true)}>
            <Tune />
          </IconButton>
        )
      }
      {permissions.canEditInformation && isMain && (
        <IconButton
          label="Delete Step"
          variant="error"
          onClick={() => open('deleteElement')}
        >
          <Delete />
        </IconButton>
      )}
    </>
  );
};

export default Actions;
