import { useContext } from 'react';
import { ModalContext } from '../providers/feature-modals';

const useFeatureModals = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('Context not found');
  }

  return context;
};

export default useFeatureModals;
