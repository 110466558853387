import {
  ModalCreateElement,
  ModalDeleteElement,
  ModalEditInformation,
  ModalSort,
} from '../../../../../../features';
import { useFeatureModals, useUX } from '../../../../../../shared/hooks';
import useModule from '../../../../provider';

const Modals = ({ setTopicInformation, topicInformation }) => {
  const {
    data: { isMain, moduleInformation },
    actions: { overwriteModuleInformation },
  } = useModule();
  const {
    actions: { close },
  } = useFeatureModals();
  const {
    actions: { alert },
  } = useUX();

  if (!topicInformation) return <></>;

  const languageId = moduleInformation.language._id;
  const activities = topicInformation.activities;
  const name = topicInformation.name;
  const wrapperTopicId = topicInformation.wrapper._id;

  const editInformationCallback = (data) => {
    close('editInformation');
    if (!data) return;
    const { name, description, updatedAt } = data;
    alert.openSuccess('Topic information updated successfully!');
    setTopicInformation((topic) => ({
      ...topic,
      name,
      description,
      updatedAt,
    }));
  };

  const createElementCallback = (data) => {
    close('createElement');
    if (!data) return;
    alert.openSuccess('New activity successfully created!');
    const newActivity = data.activities.find(
      ({ language }) => language === languageId
    );
    const { activities } = topicInformation;
    const newActivities = [...activities, newActivity];
    setTopicInformation((prev) => ({
      ...prev,
      activities: newActivities,
    }));
  };

  const sortCallback = (data) => {
    close('sort');
    if (!data) return;
    alert.openSuccess('Activities reordered successfully!');
    setTopicInformation((prev) => ({
      ...prev,
      activities: data,
    }));
  };

  const deleteElementCallback = (data) => {
    close('deleteElement');
    if (!data) return;
    alert.openSuccess(
      `Topic ${name}, all its translations (if any) and all its children (if any) were successfully deleted.`
    );
    overwriteModuleInformation({
      topics: moduleInformation.topics.filter(
        ({ _id }) => _id !== topicInformation._id
      ),
    });
  };

  return (
    <>
      <ModalEditInformation
        isMain={isMain}
        parent={topicInformation}
        parentName="topic"
        callback={editInformationCallback}
      />
      <ModalCreateElement
        payload={{
          wrapperTopic: wrapperTopicId,
        }}
        model="activity"
        callback={createElementCallback}
      />
      <ModalSort
        isMain={isMain}
        items={activities}
        model="topic"
        title={name}
        wrapperId={wrapperTopicId}
        callback={sortCallback}
      />
      <ModalDeleteElement
        callback={deleteElementCallback}
        id={wrapperTopicId}
        name={name}
        elementName="topic"
      />
    </>
  );
};

export default Modals;
