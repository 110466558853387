import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRequest, useUX } from '../../../shared/hooks';
import services from '../services';

const ModuleContext = createContext({});

export const ModuleProvider = ({ children }) => {
  const api = useRequest();
  const {
    actions: { alert, setLoading },
  } = useUX();
  const { getModuleById, getModulesByWrapperId, getReviewTree } = useMemo(
    () => services(api),
    [api]
  );
  const [moduleId, setModuleId] = useState();
  const [moduleInformation, setModuleInformation] = useState();
  const [siblingsInformation, setSiblingsInformation] = useState();
  const [openModalCreateSibling, setOpenModalCreateSibling] = useState(false);
  const [stepTypes, setStepTypes] = useState();
  const [reviewTree, setReviewTree] = useState();

  const overwriteModuleInformation = useCallback((info = {}) => {
    setModuleInformation((prev) => ({
      ...prev,
      ...info,
    }));
  }, []);

  useEffect(() => {
    if (!moduleId) return;
    setLoading(true);
    getModuleById(moduleId)
      .then((moduleObject) => setModuleInformation(moduleObject))
      .catch(({ response }) =>
        alert.openError(response?.data?.message || 'Error fetching module')
      )
      .finally(() => setLoading(false));
  }, [alert, getModuleById, moduleId, setLoading]);

  useEffect(() => {
    if (!moduleInformation?.wrapper) return;
    setLoading(true);
    getModulesByWrapperId(moduleInformation.wrapper)
      .then((modules) =>
        setSiblingsInformation(
          modules.filter(({ _id }) => _id !== moduleInformation._id)
        )
      )
      .catch(({ response }) =>
        alert.openError(response?.data?.message || 'Error fetching module')
      )
      .finally(() => setLoading(false));
  }, [alert, getModulesByWrapperId, moduleInformation, setLoading]);

  useEffect(() => {
    api
      .get('/wrapper-step-type')
      .then(({ data }) => setStepTypes(data))
      .catch(() => {
        window.location.reload();
      });
  }, [api]);

  useEffect(() => {
    if (!moduleInformation || moduleInformation.language.main)
      return setReviewTree();
    if (reviewTree?._id === moduleId) return;
    getReviewTree(moduleId)
      .then((tree) => setReviewTree(tree))
      .catch(() => {
        alert.openError('Error fetching review tree');
        setReviewTree();
      });
  }, [alert, getReviewTree, moduleId, moduleInformation, reviewTree]);

  const value = useMemo(
    () => ({
      data: {
        isMain: moduleInformation?.language.main,
        moduleInformation,
        openModalCreateSibling,
        reviewTree,
        siblingsInformation,
        stepTypes,
      },
      actions: {
        setModuleId,
        setOpenModalCreateSibling,
        overwriteModuleInformation,
      },
    }),
    [
      moduleInformation,
      openModalCreateSibling,
      overwriteModuleInformation,
      reviewTree,
      siblingsInformation,
      stepTypes,
    ]
  );

  return (
    <ModuleContext.Provider value={value}>{children}</ModuleContext.Provider>
  );
};

const useModule = () => {
  const context = useContext(ModuleContext);

  if (!context) {
    throw new Error('Context not found');
  }

  return context;
};

export default useModule;
