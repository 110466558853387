import { useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@material-ui/core';

import { InlineActions, Modal } from '../../../components';
import useValidation from '../provider';
import { useRequest, useUX } from '../../../shared/hooks';

const ModalCreate = ({ callback, open }) => {
  const api = useRequest();
  const {
    data: { loading },
    actions: { alert, setLoading },
  } = useUX();
  const { wrapperStep, validationTypes } = useValidation();
  const [type, setType] = useState(null);
  const [name, setName] = useState('');
  const [content, setContent] = useState('');

  const clearForm = () => {
    setType(null);
    setName('');
    setContent('');
  };

  const handleSubmit = () => {
    if (!type || !name || !content) return;
    const payload = {
      name,
      description: content,
      wrapperStep: wrapperStep._id,
      type,
    };
    setLoading(true);
    api
      .post('/validation', payload)
      .then(({ data }) => {
        clearForm();
        callback(data);
      })
      .catch((error) => alert.openError(error?.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const handleCancel = () => {
    clearForm();
    callback();
  };

  return (
    <Modal open={open}>
      <Typography variant="h4">Create new validation</Typography>
      <FormField>
        <TextField
          label="Name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </FormField>
      <FormField>
        <InputLabel>Validation Type</InputLabel>
        <Select
          fullWidth
          name="type"
          value={type}
          onChange={(event) => setType(event.target.value)}
          placeholder="Type"
        >
          {validationTypes?.map((option) => (
            <MenuItem key={option._id} value={option._id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormField>
      <FormField>
        <TextField
          label="Content"
          value={content}
          onChange={(event) => setContent(event.target.value)}
          multiline
          minRows={5}
          rows={5}
          maxRows={25}
        />
      </FormField>
      <InlineActions>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          disabled={loading}
        >
          Submit
        </Button>
        <Button variant="contained" onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>
      </InlineActions>
    </Modal>
  );
};

export default ModalCreate;

const FormField = styled(FormControl)({
  width: '100%',
  marginTop: 20,
});
