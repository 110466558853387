import { Modal } from '../../../../components';
import Header from './components/Header';
import useModule from '../../provider';
import Form from './components/Form';
import Actions from './components/Actions';
import useCreateSibling from './hook';

const ModalCreateSibling = () => {
  const {
    data: {
      moduleInformation,
      openModalCreateSibling,
      siblingsInformation = [],
    },
    actions: { setOpenModalCreateSibling },
  } = useModule();
  const {
    data: { formValues, languagesWithAvailability },
    actions: { setFormValues },
  } = useCreateSibling([moduleInformation, ...siblingsInformation]);

  const handleSubmit = () => {
    setOpenModalCreateSibling(false);
  };

  const handleCancel = () => setOpenModalCreateSibling(false);

  if (!moduleInformation || !siblingsInformation) return <></>;

  return (
    <Modal open={openModalCreateSibling}>
      <Header name={moduleInformation.name} />
      <Form
        defaultName={moduleInformation.name}
        defaultDescription={moduleInformation.description}
        languages={languagesWithAvailability}
        setValues={setFormValues}
        values={formValues}
      />
      <Actions onCancel={handleCancel} onSubmit={handleSubmit} />
    </Modal>
  );
};

export default ModalCreateSibling;
