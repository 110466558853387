import { Box, Button as MaterialButton, styled } from '@material-ui/core';

const Actions = ({ onCancel, onSubmit }) => (
  <JustifiedFlexbox>
    <Button color="primary" variant="contained" onClick={onSubmit}>
      Create
    </Button>
    <Button color="primary" variant="contained" onClick={onCancel}>
      Close
    </Button>
  </JustifiedFlexbox>
);

export default Actions;

const JustifiedFlexbox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const Button = styled(MaterialButton)({
  width: 'calc(50% - 5px)',
});
