import { List } from '@material-ui/core';

import AddNewButton from '../../AddNewButton';
import TopicItem from '../TopicItem';
import useModule from '../../../provider';
import { useFeatureModals } from '../../../../../shared/hooks';
import { getCurrentReviewTree } from '../../../helpers';

const TopicsList = () => {
  const {
    data: { isMain, moduleInformation, reviewTree },
  } = useModule();
  const {
    actions: { open },
  } = useFeatureModals();

  if (!moduleInformation) return <></>;

  const {
    topics,
    language: { _id: language },
  } = moduleInformation;

  return (
    <List component="nav">
      {topics.map((topic, index) => (
        <TopicItem
          key={topic._id}
          topic={topic}
          reviewTree={getCurrentReviewTree(reviewTree, 'topics', index)}
          language={language}
        />
      ))}
      {isMain && (
        <AddNewButton onClick={() => open('createElement')}>
          Create new topic
        </AddNewButton>
      )}
    </List>
  );
};

export default TopicsList;
