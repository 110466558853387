import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import {
  HomePage,
  HomeUser,
  Register,
  LoginCallback,
  Module,
  Topic,
  Activity,
  Step,
  Validation,
  AdminPage,
} from '../pages';
import { Header } from '../components';
import { useLocalStorage } from '../shared/hooks';

const Routes = () => {
  const [, setIsLoggedInCache] = useLocalStorage('logged');
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <PublicRoute exact path="/" component={HomePage} />
        <PublicRoute exact path="/callback" component={LoginCallback} />
        <PrivateRoute exact path="/home" component={HomeUser} />
        <PrivateRoute exact path="/register" component={Register} />
        <PrivateRoute exact path="/module/:id" component={Module} />
        <PrivateRoute exact path="/topic/:id" component={Topic} />
        <PrivateRoute exact path="/activity/:id" component={Activity} />
        <PrivateRoute exact path="/step/:id" component={Step} />
        <PrivateRoute exact path="/admin" component={AdminPage} />
        <PrivateRoute
          exact
          path="/step/:id/validation"
          component={Validation}
        />
        <PublicRoute
          exact
          path="/logout"
          render={() => {
            setIsLoggedInCache(false);
            window.location.href = '/';
          }}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
