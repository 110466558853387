import React from 'react';
import {
  Container,
  CssBaseline,
  ThemeProvider,
  StylesProvider,
} from '@material-ui/core';
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { UserProvider } from './shared/providers/user';
import { UXProvider } from './shared/providers/ux';
import { theme } from './shared/constants';

import Routes from './routes';

const {
  REACT_APP_KEYCLOAK_CLIENT_ID: KEYCLOAK_CLIENT_ID,
  REACT_APP_KEYCLOAK_AUTH_URL: KEYCLOAK_AUTH_URL,
  REACT_APP_KEYCLOAK_REALM: KEYCLOAK_REALM,
} = process.env;

const keycloak = new Keycloak({
  clientId: KEYCLOAK_CLIENT_ID,
  url: KEYCLOAK_AUTH_URL,
  realm: KEYCLOAK_REALM,
});

function App() {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        checkLoginIframe: false,
      }}
    >
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UXProvider>
            <UserProvider>
              <CssBaseline />
              <Container maxWidth="xl">
                <Routes />
              </Container>
            </UserProvider>
          </UXProvider>
        </ThemeProvider>
      </StylesProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
