import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { useRequest } from '../../shared/hooks';

const ValidationsContext = createContext({});

export const ValidationsProvider = ({ children }) => {
  const { id } = useParams();
  const api = useRequest();
  const [wrapperStep, setWrapperStep] = useState({ validations: [] });
  const [validationTypes, setValidationTypes] = useState();

  useEffect(() => {
    api
      .get(`/wrapper-step/${id}/validation`)
      .then(({ data }) => setWrapperStep(data));
  }, [api, id]);

  useEffect(() => {
    api.get('/validation-type').then(({ data }) => setValidationTypes(data));
  }, [api]);

  const validations = useMemo(
    () =>
      validationTypes
        ? wrapperStep.validations.map(
            ({ _id, name, description, validationType }) => ({
              _id,
              title: name,
              description,
              validationType: validationTypes.find(
                ({ _id }) => _id === validationType
              ),
            })
          )
        : [],
    [validationTypes, wrapperStep.validations]
  );

  const appendValidation = useCallback(
    (data) =>
      setWrapperStep((prev) => ({
        ...prev,
        validations: [...prev.validations, data],
      })),
    []
  );

  const deleteValidationById = useCallback(
    (id) =>
      setWrapperStep((prev) => ({
        ...prev,
        validations: prev.validations.filter(({ _id }) => _id !== id),
      })),
    []
  );

  const value = {
    appendValidation,
    deleteValidationById,
    validations,
    validationTypes,
    wrapperStep,
  };

  return (
    <ValidationsContext.Provider value={value}>
      {children}
    </ValidationsContext.Provider>
  );
};

const useValidation = () => {
  const context = useContext(ValidationsContext);

  if (!context) {
    throw new Error('Context not found');
  }

  return context;
};

export default useValidation;
