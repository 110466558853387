import { useCallback, useState } from 'react';
import { ListItem, ListItemText } from '@material-ui/core';

import ActivitiesList from '../ActivitiesList';
import Actions from './components/Actions';
import Modals from './components/Modals';
import FeatureModalsProvider from '../../../../../shared/providers/feature-modals';
import useTopicItem from './hook';
import useModule from '../../../provider';
import { usePermissions } from '../../../../../shared/hooks';
import { ChipsList } from '../../../../../components';

const TopicItem = ({ reviewTree = {}, topic, language }) => {
  const [topicInformation, setTopicInformation] = useState(topic);
  const setTopic = useCallback(
    (info) =>
      setTopicInformation(({ wrapper }) => ({
        ...info,
        wrapper,
      })),
    []
  );
  const permissions = usePermissions(language);
  const overwriteTopicInformation = useCallback((info = {}) => {
    setTopicInformation((prev) => ({
      ...prev,
      ...info,
    }));
  }, []);

  const { isOpen, Icon, trigger } = useTopicItem({
    id: topic._id,
    setTopic,
  });
  const {
    data: { isMain },
  } = useModule();

  if (!topic) return <></>;

  const primaryText = topicInformation.name;
  const secondaryText = `${topicInformation.wrapper.idInteger} / ${topicInformation.idInteger} - ${topicInformation.description}`;

  return (
    <>
      <ListItem>
        <ListItemText primary={primaryText} secondary={secondaryText} />
        <ChipsList tree={reviewTree} />
        <Actions
          fetchTrigger={trigger}
          permissions={permissions}
          topicInformation={topicInformation}
        />
        <Icon />
      </ListItem>
      <ActivitiesList
        open={isOpen}
        topicInformation={topicInformation}
        showAddButton={isMain && permissions.canAddNew}
        reviewTree={reviewTree.activities}
        overwriteTopicInformation={overwriteTopicInformation}
        language={language}
      />
      <Modals
        topicInformation={topicInformation}
        setTopicInformation={setTopicInformation}
      />
    </>
  );
};

const TopicItemWrapped = (props) => (
  <FeatureModalsProvider>
    <TopicItem {...props} />
  </FeatureModalsProvider>
);

export default TopicItemWrapped;
