import { useContext } from 'react';
import { UXContext } from '../providers/ux';

const useUX = () => {
  const context = useContext(UXContext);

  if (!context) {
    throw new Error('Context not found');
  }

  return context;
};

export default useUX;
