import ModalCreateSibling from '../ModalCreateSibling';
import {
  ModalCreateElement,
  ModalEditInformation,
  ModalSort,
} from '../../../../features';
import { useFeatureModals, useUX } from '../../../../shared/hooks';
import useModule from '../../provider';

const Modals = () => {
  const {
    actions: { close },
  } = useFeatureModals();
  const {
    actions: { alert },
  } = useUX();
  const {
    data: { isMain, moduleInformation },
    actions: { overwriteModuleInformation },
  } = useModule();

  if (!moduleInformation) return <></>;

  const languageId = moduleInformation.language._id;
  const wrapperModuleId = moduleInformation.wrapper;
  const topics = moduleInformation.topics;
  const name = moduleInformation.name;

  const editInformationCallback = (data) => {
    close('editInformation');
    if (!data) return;
    const { name, description, review, updatedAt } = data;
    alert.openSuccess('Module information updated successfully!');
    overwriteModuleInformation({ name, description, review, updatedAt });
  };

  const createElementCallback = (data) => {
    close('createElement');
    if (!data) return;
    alert.openSuccess('New topic successfully created!');
    const newTopic = data.topics.find(
      ({ language }) => language === languageId
    );
    const { topics } = moduleInformation;
    const newTopics = [...topics, newTopic];
    overwriteModuleInformation({ topics: newTopics });
  };

  const sortCallback = (data) => {
    close('sort');
    if (!data) return;
    alert.openSuccess('Topics reordered successfully!');
    overwriteModuleInformation({ topics: data });
  };

  return (
    <>
      <ModalEditInformation
        isMain={isMain}
        parent={moduleInformation}
        parentName="module"
        callback={editInformationCallback}
      />
      <ModalCreateSibling />
      <ModalCreateElement
        payload={{
          wrapperModule: wrapperModuleId,
        }}
        model="topic"
        callback={createElementCallback}
      />
      <ModalSort
        isMain={isMain}
        items={topics}
        model="module"
        title={name}
        wrapperId={wrapperModuleId}
        callback={sortCallback}
      />
    </>
  );
};

export default Modals;
