import React from 'react';
import { ArrowDownward, ArrowUpward, Delete } from '@material-ui/icons';
import { IconButton } from '../../../components';

const Options = ({
  index = 0,
  last = false,
  handleOrderChange = () => {},
  handleDelete = () => {},
}) => {
  return (
    <>
      <IconButton
        onClick={() => handleOrderChange(index, -1)}
        disabled={index < 1}
      >
        <ArrowUpward />
      </IconButton>
      <IconButton onClick={() => handleOrderChange(index, 1)} disabled={last}>
        <ArrowDownward />
      </IconButton>
      <IconButton onClick={handleDelete}>
        <Delete />
      </IconButton>
    </>
  );
};

export default Options;
