import { Typography } from '@material-ui/core';

const Header = ({ isMain, name, parentName }) => (
  <>
    <Typography variant="h5">
      {`Edit information of ${parentName}: ${name}`}
    </Typography>
    {isMain && (
      <Typography variant="caption">
        This will trigger review across all translations.
      </Typography>
    )}
  </>
);

export default Header;
